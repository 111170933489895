import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getInfo,
  getBranchs,
  getBranchList,
  getPoints,
  getPoint,
  patchPoint,
  getPouchTracks,
  getFreightTracks,
  getDrivers,
  getFreights,
  getFreight,
  postFreight,
  patchFreight,
  deleteFreight,
  printfreight,
  getDocuments,
  getDocument,
  postDocument,
  patchDocument,
  deleteDocument,
  printDocument,
  initDocument,
  initFreight,
  getPointCheckId,
  getReceiptDocuDownloadExcel,
  getReceiptFreightDownloadExcel,
  getPouchDownloadExcel,
  getFreightDownloadExcel,
  getPointToBranch,
  setFreightPrint,
  setDocumentPrint,
  initFreightPrint,
  initDocumentPrint,
  postFreightsUpload,
  postDocumentsUpload,
} = actions.CustomerAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  points: state.CustomerReducer.points,
  point: state.CustomerReducer.point,
  branchs: state.CustomerReducer.branchs,
  pouchTracks: state.CustomerReducer.pouchTracks,
  pouchTracksTotal: state.CustomerReducer.pouchTracksTotal,
  freightTracks: state.CustomerReducer.freightTracks,
  freightTracksTotal: state.CustomerReducer.freightTracksTotal,
  documents: state.CustomerReducer.documents,
  documentsTotal: state.CustomerReducer.documentsTotal,
  document: state.CustomerReducer.document,
  freights: state.CustomerReducer.freights,
  freightsTotal: state.CustomerReducer.freightsTotal,
  freight: state.CustomerReducer.freight,
  info: state.CustomerReducer.info,
  drivers: state.CustomerReducer.drivers,
  documentPrint: state.CustomerReducer.documentPrint,
  freightPrint: state.CustomerReducer.freightPrint,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getPointCheckId: (param) => dispatch(getPointCheckId(param)),
  getInfo: () => dispatch(getInfo()),
  getDrivers: () => dispatch(getDrivers()),
  getFreightsTracks: (param) => dispatch(getFreightsTracks(param)),
  getPoints: (param) => dispatch(getPoints(param)),
  getPoint: (param) => dispatch(getPoint(param)),
  patchPoint: (param) => dispatch(patchPoint(param)),
  getBranchs: (param) => dispatch(getBranchs(param)),
  getBranchList: (param) => dispatch(getBranchList(param)),
  getPouchTracks: (param) => dispatch(getPouchTracks(param)),
  getFreightTracks: (param) => dispatch(getFreightTracks(param)),
  getFreights: (param) => dispatch(getFreights(param)),
  getFreight: (param) => dispatch(getFreight(param)),
  postFreight: (param) => dispatch(postFreight(param)),
  patchFreight: (param) => dispatch(patchFreight(param)),
  deleteFreight: (param) => dispatch(deleteFreight(param)),
  printfreight: (param) => dispatch(printfreight(param)),
  getDocuments: (param) => dispatch(getDocuments(param)),
  getDocument: (param) => dispatch(getDocument(param)),
  postDocument: (param) => dispatch(postDocument(param)),
  patchDocument: (param) => dispatch(patchDocument(param)),
  deleteDocument: (param) => dispatch(deleteDocument(param)),
  printDocument: (param) => dispatch(printDocument(param)),
  initDocument: () => dispatch(initDocument()),
  initFreight: () => dispatch(initFreight()),
  getReceiptDocuDownloadExcel: (param) => dispatch(getReceiptDocuDownloadExcel(param)),
  getReceiptFreightDownloadExcel: (param) => dispatch(getReceiptFreightDownloadExcel(param)),
  getPouchDownloadExcel: (param) => dispatch(getPouchDownloadExcel(param)),
  getFreightDownloadExcel: (param) => dispatch(getFreightDownloadExcel(param)),
  getPointToBranch: (param) => dispatch(getPointToBranch(param)),
  setFreightPrint: (param) => dispatch(setFreightPrint(param)),
  setDocumentPrint: (param) => dispatch(setDocumentPrint(param)),
  initFreightPrint: () => dispatch(initFreightPrint()),
  initDocumentPrint: () => dispatch(initDocumentPrint()),
  postDocumentsUpload: (param) => dispatch(postDocumentsUpload(param)),
  postFreightsUpload: (param) => dispatch(postFreightsUpload(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
