import React, { useEffect, useRef, useState } from "react"
import Layout from "../component/common/layout"
import styled from "styled-components"
import SEO from "../seo"
import moment from "moment"
import { useBarcode } from "react-barcodes"
import arrow from "../../images/print-arrow.png"
import logo from "../../images/print-logo.png"
import logo2 from "../../images/logo2.png"
import mobile from "../../images/print-mobile.png"
import CustomerContainer from "../../containers/customerContainer"

//150 x 100 mm
const ZeplinPxToMM = (px) => {
  return px * 0.320118
}

const PrintFreight = (props) => {
  const { freightPrint, initFreightPrint, getDrivers, drivers } = props
  const today = moment()
  const generateBarcode = (num) => {
    const { inputRef } = useBarcode({
      value: num,
      options: {
        font: "Noto Sans KR",
        fontOptions: "bold",
        fontSize: `${ZeplinPxToMM(45)}px`,
        textMargin: 0,
        height: ZeplinPxToMM(100),
        margin: 0,
        width: ZeplinPxToMM(5.5),
      },
    })
    return <img ref={inputRef}></img>
  }
  const getData = async () => {
    await getDrivers()
  }
  useEffect(() => {
    getData()
    return () => {
      initFreightPrint()
    }
  }, [])

  const on = false

  return (
    <Container style={{ overflowY: "scroll", width: "100%", height: "100%" }}>
      {freightPrint.map((v, i) => (
        <FlexDiv
          key={i}
          style={{
            minWidth: `150mm`,
            maxWidth: "150mm",
            minHeight: `99mm`,
            maxHeight: "99mm",
            pageBreakAfter: "always",
            marginTop: `${ZeplinPxToMM(10)}px`,
          }}
        >
          {/* 왼쪽 페이지*/}
          <FlexDiv
            direction={"column"}
            style={{ position: "relative", width: ZeplinPxToMM(885), height: "100%", borderRight: `${ZeplinPxToMM(1)}px solid #000` }}
          >
            <Real>
              <Pstyled1 style={{ position: "absolute", top: 14, left: 125, width: 150, letterSpacing: ZeplinPxToMM(3), textAlign: "center" }}>
                {v.freightNo}
              </Pstyled1>
              <Pstyled2 style={{ position: "absolute", top: 42, left: 140, width: 130, whiteSpace: "pre" }}>{`  ${today.format(
                "YY"
              )}        ${today.format("MM")}        ${today.format("DD")} `}</Pstyled2>
              <div
                style={{
                  position: "absolute",
                  top: 65,
                  left: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${ZeplinPxToMM(885)}px`,
                }}
              >
                {generateBarcode(v.freightNo)}
              </div>
              <Pstyled2
                style={{ position: "absolute", top: 125, left: 85, width: 180, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
              >
                {v.recvTeleNo}
              </Pstyled2>
              <Pstyled2
                style={{
                  position: "absolute",
                  top: 175,
                  left: 85,
                  width: 180,
                  color: "#000",
                  fontSize: ZeplinPxToMM(25),
                  whiteSpace: "pre-wrap",
                  textAlign: "center",
                }}
              >
                {v.recvAddr}
              </Pstyled2>
              <Pstyled2
                style={{
                  position: "absolute",
                  top: 190,
                  left: 85,
                  width: 180,
                  color: "#000",
                  fontSize: ZeplinPxToMM(25),
                  whiteSpace: "pre-wrap",
                  textAlign: "center",
                }}
              >
                {v.recvAddrDetail}
              </Pstyled2>
              <Pstyled2
                style={{ position: "absolute", top: 223, left: 85, width: 180, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
              >
                {v.recvEmpName}
              </Pstyled2>
              <Pstyled2
                style={{ position: "absolute", top: 265, left: 85, width: 180, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
              >
                {v.recvPoint.driver ? v.recvPoint.driver.driverName : ""}
              </Pstyled2>
              <Pstyled2
                style={{ position: "absolute", top: 293, left: 85, width: 180, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
              >
                {v.recvPoint.driver ? v.recvPoint.driver.phoneNo : ""}
              </Pstyled2>
              <div
                style={{
                  position: "absolute",
                  top: 325,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${ZeplinPxToMM(885)}px`,
                }}
              >
                {generateBarcode(v.freightNo)}
              </div>
            </Real>

            {on && (
              <>
                {/* 사진,운송장,발송일자,바코드*/}
                <FlexSection
                  pl={ZeplinPxToMM(30)}
                  pr={ZeplinPxToMM(30)}
                  pt={ZeplinPxToMM(30)}
                  pb={ZeplinPxToMM(30)}
                  style={{ width: "100%", height: ZeplinPxToMM(371) }}
                >
                  <FlexDiv style={{ width: ZeplinPxToMM(170), height: "100%" }}>
                    <img style={{ width: ZeplinPxToMM(170), height: ZeplinPxToMM(311) }} src={mobile} alt={"모바일 이미지"}></img>
                  </FlexDiv>
                  <FlexDiv grow={1} pl={ZeplinPxToMM(20)} direction={"column"}>
                    <FlexDiv grow={1}>
                      <FlexDiv alignItems={"center"} style={{ width: ZeplinPxToMM(200), height: ZeplinPxToMM(73.4) }}>
                        <Pstyled2>{"운송장 번호"}</Pstyled2>
                      </FlexDiv>
                      <FlexDiv grow={1} alignItems={"center"} justify={"center"}>
                        {/* <Pstyled1 style={{ letterSpacing: ZeplinPxToMM(3) }}>{v.freightNo}</Pstyled1> */}
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv pt={ZeplinPxToMM(8)}>
                      <FlexDiv alignItems={"center"} style={{ width: ZeplinPxToMM(200), height: ZeplinPxToMM(73.4) }}>
                        <Pstyled2>{"발송일자"}</Pstyled2>
                      </FlexDiv>
                      <FlexDiv grow={1} alignItems={"center"} justify={"space-between"}>
                        {/* <Pstyled2>{`${today.format("YYYY")} 년    ${today.format("MM")} 월     ${today.format("DD")} 일`}</Pstyled2> */}
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv justify={"center"} alignItems={"center"} style={{ height: "100%" }}>
                      {/* {generateBarcode(v.freightNo)} */}
                    </FlexDiv>
                  </FlexDiv>
                </FlexSection>

                {/*받으시는 분*/}
                <FlexSection style={{ justifyContent: "center", height: "fit-content" }}>
                  <TableStyle1 lineHeight={`${ZeplinPxToMM(50)}px`} name={`받\n으\n시\n는\n분`}>
                    <FlexDiv>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"center"}
                        style={{
                          borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          width: ZeplinPxToMM(134),
                          height: ZeplinPxToMM(90),
                          backgroundColor: "#def3ff",
                        }}
                      >
                        <Pstyled4>{"연락처"}</Pstyled4>
                      </FlexDiv>
                      <FlexDiv grow={1} style={{}} alignItems={"center"} pl={ZeplinPxToMM(15)}>
                        {/* <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25) }}>{v.recvTeleNo}</Pstyled2> */}
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"center"}
                        style={{
                          borderTop: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          borderBottom: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          minWidth: ZeplinPxToMM(134),
                          height: ZeplinPxToMM(210),
                          backgroundColor: "#def3ff",
                        }}
                      >
                        <Pstyled4>{"주소"}</Pstyled4>
                      </FlexDiv>
                      <FlexDiv
                        grow={1}
                        pl={ZeplinPxToMM(15)}
                        alignItems={"center"}
                        style={{ borderTop: `${ZeplinPxToMM(2)}px solid #0084d1`, borderBottom: `${ZeplinPxToMM(2)}px solid #0084d1` }}
                      >
                        {/* <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25), whiteSpace: "pre-wrap" }}>{v.recvAddr}</Pstyled2> */}
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"center"}
                        style={{
                          borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          width: ZeplinPxToMM(134),
                          height: ZeplinPxToMM(90),
                          backgroundColor: "#def3ff",
                        }}
                      >
                        <Pstyled4>{"성명"}</Pstyled4>
                      </FlexDiv>
                      <FlexDiv grow={1} pl={ZeplinPxToMM(15)} alignItems={"center"}>
                        {/* <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25) }}>{v.recvEmpName}</Pstyled2> */}
                      </FlexDiv>
                    </FlexDiv>
                  </TableStyle1>
                </FlexSection>

                {/*배송기사*/}
                <FlexSection mt={ZeplinPxToMM(40)} style={{ height: ZeplinPxToMM(187) }}>
                  <TableStyle1 lineHeight={`${ZeplinPxToMM(39.2)}px`} name={"배\n송\n기\n사"}>
                    <FlexDiv>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"center"}
                        style={{
                          borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          width: ZeplinPxToMM(134),
                          height: ZeplinPxToMM(90),
                          backgroundColor: "#def3ff",
                        }}
                      >
                        <Pstyled4>{"성명"}</Pstyled4>
                      </FlexDiv>
                      <FlexDiv grow={1} pl={ZeplinPxToMM(15)} alignItems={"center"}>
                        <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25) }}>
                          {/* {v.recvPoint.driver ? v.recvPoint.driver.driverName : ""} */}
                          {/* {drivers.find((i) => i.driverSeq === v.recvPoint.driverSeq) !== undefined &&
                        drivers.find((i) => i.driverSeq === v.recvPoint.driverSeq).driverName} */}
                        </Pstyled2>
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"center"}
                        style={{
                          borderTop: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          width: ZeplinPxToMM(134),
                          height: ZeplinPxToMM(90),
                          backgroundColor: "#def3ff",
                        }}
                      >
                        <Pstyled4>{"연락처"}</Pstyled4>
                      </FlexDiv>
                      <FlexDiv grow={1} pl={ZeplinPxToMM(15)} alignItems={"center"} style={{ borderTop: `${ZeplinPxToMM(2)}px solid #0084d1` }}>
                        <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25) }}>
                          {/* {v.recvPoint.driver ? v.recvPoint.driver.phoneNo : ""} */}
                          {/* {drivers.find((i) => i.driverSeq === v.recvPoint.driverSeq) !== undefined &&
                        drivers.find((i) => i.driverSeq === v.recvPoint.driverSeq).phoneNo} */}
                        </Pstyled2>
                      </FlexDiv>
                    </FlexDiv>
                  </TableStyle1>
                </FlexSection>
                {/*바코드*/}
                <FlexSection pt={ZeplinPxToMM(30)} style={{ justifyContent: "center", height: `100%` }}>
                  {/* {generateBarcode(v.freightNo)} */}
                </FlexSection>
              </>
            )}
          </FlexDiv>

          {/* 오른쪽 페이지*/}
          <FlexDiv
            direction={"column"}
            grow={1}
            style={{ position: "relative", minWidth: ZeplinPxToMM(885), maxWidth: ZeplinPxToMM(885), height: "100%" }}
          >
            <Real>
              <Pstyled2
                style={{ position: "absolute", top: 25, left: 60, width: 75, color: "#000", fontSize: ZeplinPxToMM(60), whiteSpace: "pre-wrap" }}
              >
                {v.sendBranch.branchName}
              </Pstyled2>
              <Pstyled2
                style={{ position: "absolute", top: 25, left: 213, width: 75, color: "#000", fontSize: ZeplinPxToMM(60), whiteSpace: "pre-wrap" }}
              >
                {v.recvBranch.branchName}
              </Pstyled2>
              <Pstyled2
                style={{ position: "absolute", top: 70, left: 75, width: 200, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
              >
                {v.sendTeleNo}
              </Pstyled2>
              <Pstyled2
                style={{ position: "absolute", top: 98, left: 75, width: 200, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
              >
                {`${v.sendPoint.customer.custName} / ${v.sendPoint.pointName}`}
              </Pstyled2>
              <Pstyled2
                style={{ position: "absolute", top: 127, left: 75, width: 200, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
              >
                {v.sendEmpName}
              </Pstyled2>
              <div
                style={{
                  position: "absolute",
                  top: 157,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${ZeplinPxToMM(885)}px`,
                }}
              >
                {generateBarcode(v.freightNo)}
              </div>
              <Pstyled2
                style={{ position: "absolute", top: 230, left: 50, width: 200, color: "#000", fontSize: ZeplinPxToMM(25), whiteSpace: "pre-wrap" }}
              >{`${v.remark ? v.remark : "없음"} / ${v.itemName}`}</Pstyled2>

              <div style={{ position: "absolute", top: 305, left: 160, display: "flex", justifyContent: "center", alignItems: "center" }}>
                {v.payType === 1 && <Circle />}
              </div>
              <div style={{ position: "absolute", top: 305, left: 195, display: "flex", justifyContent: "center", alignItems: "center" }}>
                {v.payType === 2 && <Circle />}
              </div>
              <div style={{ position: "absolute", top: 305, left: 235, display: "flex", justifyContent: "center", alignItems: "center" }}>
                {v.payType === 3 && <Circle />}
              </div>
            </Real>
            {on && (
              <>
                {/* 발송지 -> 도착지*/}
                <FlexSection pt={ZeplinPxToMM(30)} pb={ZeplinPxToMM(30)} style={{ height: ZeplinPxToMM(190), justifyContent: "center" }}>
                  <FlexDiv style={{ width: "130px" }}>
                    <TableStyle1 name={"발\n송\n지"}>
                      <FlexDiv pl={ZeplinPxToMM(15)} alignItems={"center"} style={{ width: "100%", height: "100%" }}>
                        {/* <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25), whiteSpace: "pre-wrap" }}>{v.sendBranch.addr}</Pstyled2> */}
                      </FlexDiv>
                    </TableStyle1>
                  </FlexDiv>
                  <FlexDiv justify={"center"} alignItems={"center"}>
                    <img src={arrow} alt={"화살표 "} style={{ width: ZeplinPxToMM(70), height: ZeplinPxToMM(59) }}></img>
                  </FlexDiv>
                  <FlexDiv style={{ width: "130px" }}>
                    <TableStyle1 name={"도\n착\n지"}>
                      <FlexDiv pl={ZeplinPxToMM(15)} alignItems={"center"} style={{ width: "100%", height: "100%" }}>
                        {/* <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25), whiteSpace: "pre-wrap" }}>{v.recvBranch.addr}</Pstyled2> */}
                      </FlexDiv>
                    </TableStyle1>
                  </FlexDiv>
                </FlexSection>

                {/* 보내는분*/}
                <FlexSection style={{ height: "fit-content", justifyContent: "space-between" }}>
                  <TableStyle1 lineHeight={`${ZeplinPxToMM(50)}px`} name={"보\n내\n는\n분"}>
                    <FlexDiv>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"center"}
                        style={{
                          borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          width: ZeplinPxToMM(134),
                          height: ZeplinPxToMM(90),
                          backgroundColor: "#def3ff",
                        }}
                      >
                        <Pstyled4>{"연락처"}</Pstyled4>
                      </FlexDiv>
                      <FlexDiv grow={1} pl={ZeplinPxToMM(15)} alignItems={"center"}>
                        {/* <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25) }}>{v.sendPoint.teleNo}</Pstyled2> */}
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"center"}
                        style={{
                          borderTop: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          borderBottom: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          width: ZeplinPxToMM(134),
                          height: ZeplinPxToMM(90),
                          backgroundColor: "#def3ff",
                        }}
                      >
                        <Pstyled4 style={{ whiteSpace: "pre-wrap" }}>{"거래처명\n/지점명"}</Pstyled4>
                      </FlexDiv>
                      <FlexDiv
                        grow={1}
                        pl={ZeplinPxToMM(15)}
                        alignItems={"center"}
                        style={{ borderTop: `${ZeplinPxToMM(2)}px solid #0084d1`, borderBottom: `${ZeplinPxToMM(2)}px solid #0084d1` }}
                      >
                        {/* <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25) }}>
                          {`${v.sendPoint.customer.custName} / ${v.sendPoint.pointName}`}
                        </Pstyled2> */}
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"center"}
                        style={{
                          borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                          width: ZeplinPxToMM(134),
                          height: ZeplinPxToMM(90),
                          backgroundColor: "#def3ff",
                        }}
                      >
                        <Pstyled4>{"성명"}</Pstyled4>
                      </FlexDiv>
                      <FlexDiv grow={1} alignItems={"center"} pl={ZeplinPxToMM(15)} style={{}}>
                        {/* <Pstyled2 style={{ color: "#000", fontSize: ZeplinPxToMM(25) }}>{v.sendPoint.empName}</Pstyled2> */}
                      </FlexDiv>
                    </FlexDiv>
                  </TableStyle1>
                </FlexSection>

                {/* 바코드 */}
                <FlexSection style={{ height: ZeplinPxToMM(180), justifyContent: "center" }}>{/* {generateBarcode(v.freightNo)} */}</FlexSection>

                {/* 비고/품명 */}
                <FlexSection mb={ZeplinPxToMM(30)} style={{ height: ZeplinPxToMM(180) }}>
                  <TableStyle1 fontSize={`${ZeplinPxToMM(33.3)}px`} lineHeight={`${ZeplinPxToMM(45.8)}px`} name={"비고/\n품명"}>
                    <FlexDiv grow={1} alignItems={"center"} pl={ZeplinPxToMM(15)} style={{}}>
                      {/* <Pstyled2
                        style={{ color: "#000", fontSize: ZeplinPxToMM(25), whiteSpace: "pre-wrap" }}
                      >{`${v.remark} / ${v.itemName}`}</Pstyled2> */}
                    </FlexDiv>
                  </TableStyle1>
                </FlexSection>

                {/* 인수한분, 신용착불선불 */}
                <FlexSection style={{ height: "fit-content" }}>
                  {/* 인수한분 */}
                  <FlexDiv style={{ width: ZeplinPxToMM(465) }} ml={ZeplinPxToMM(30)} mr={ZeplinPxToMM(9)}>
                    <FlexSection>
                      <FlexDiv
                        style={{
                          width: `${ZeplinPxToMM(92)}px`,
                          minWidth: `${ZeplinPxToMM(92)}px`,
                          height: "100%",
                          backgroundColor: "#0084d1",
                          justifyContent: "center",
                          alignItems: "center",
                          border: `${ZeplinPxToMM(4)}px solid #0084d1`,
                          borderRight: "none",
                          borderTopLeftRadius: `${ZeplinPxToMM(20)}px`,
                          borderBottomLeftRadius: `${ZeplinPxToMM(20)}px`,
                        }}
                      >
                        <Pstyled3 style={{ whiteSpace: "pre", fontSize: `${ZeplinPxToMM(35)}px`, lineHeight: `${ZeplinPxToMM(39.2)}px` }}>
                          {"인\n수\n한\n분"}
                        </Pstyled3>
                      </FlexDiv>
                      <FlexDiv
                        direction={"column"}
                        style={{
                          width: "100%",
                          height: "100%",
                          border: `${ZeplinPxToMM(4)}px solid #0084d1`,
                          borderLeft: "none",
                          borderTopRightRadius: `${ZeplinPxToMM(20)}px`,
                          borderBottomRightRadius: `${ZeplinPxToMM(20)}px`,
                        }}
                      >
                        <FlexDiv>
                          <FlexDiv
                            alignItems={"center"}
                            justify={"center"}
                            style={{
                              borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                              width: ZeplinPxToMM(84),
                              height: ZeplinPxToMM(90),
                              backgroundColor: "#def3ff",
                            }}
                          >
                            <Pstyled4>{"성명"}</Pstyled4>
                          </FlexDiv>
                          <FlexDiv alignItems={"center"} grow={1}>
                            <FlexDiv grow={2}>
                              <Pstyled4>{""}</Pstyled4>
                            </FlexDiv>
                            <FlexDiv grow={1}>
                              <Pstyled4>{"(인)"}</Pstyled4>
                            </FlexDiv>
                          </FlexDiv>
                        </FlexDiv>
                        <FlexDiv>
                          <FlexDiv
                            alignItems={"center"}
                            justify={"center"}
                            style={{
                              borderTop: `${ZeplinPxToMM(2)}px solid #0084d1`,
                              borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
                              width: ZeplinPxToMM(84),
                              height: ZeplinPxToMM(90),
                              backgroundColor: "#def3ff",
                            }}
                          >
                            <Pstyled4 style={{ whiteSpace: "pre" }}>{"인수\n일자"}</Pstyled4>
                          </FlexDiv>
                          <FlexDiv
                            grow={1}
                            alignItems={"center"}
                            justify={"space-between"}
                            style={{ borderTop: `${ZeplinPxToMM(2)}px solid #0084d1` }}
                          >
                            <Pstyled2
                              style={{ fontSize: ZeplinPxToMM(29.2), lineHeight: `${ZeplinPxToMM(35)}px` }}
                            >{` 20    년     월    일`}</Pstyled2>
                          </FlexDiv>
                        </FlexDiv>
                      </FlexDiv>
                    </FlexSection>
                  </FlexDiv>
                  {/* 신용착불선불 */}
                  <FlexDiv grow={1} mr={ZeplinPxToMM(30)}>
                    <FlexSection style={{ height: "100%" }}>
                      <FlexDiv direction={"column"} grow={1}>
                        <FlexDiv
                          style={{
                            minHeight: ZeplinPxToMM(58),
                            backgroundColor: "#0084d1",
                            justifyContent: "center",
                            alignItems: "center",
                            border: `${ZeplinPxToMM(4)}px solid #0084d1`,
                            borderTopLeftRadius: `${ZeplinPxToMM(20)}px`,
                          }}
                        >
                          <Pstyled3>{"신용"}</Pstyled3>
                        </FlexDiv>
                        <FlexDiv
                          style={{
                            height: "100%",
                            border: `${ZeplinPxToMM(4)}px solid #0084d1`,
                            borderBottomLeftRadius: `${ZeplinPxToMM(20)}px`,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <Circle /> */}
                        </FlexDiv>
                      </FlexDiv>
                      <div
                        style={{
                          width: ZeplinPxToMM(3),
                          backgroundColor: "#fff",
                          borderTop: `${ZeplinPxToMM(4)}px solid #0084d1`,
                          borderBottom: `${ZeplinPxToMM(4)}px solid #0084d1`,
                        }}
                      ></div>
                      <FlexDiv direction={"column"} grow={1}>
                        <FlexDiv
                          style={{
                            minHeight: ZeplinPxToMM(58),
                            backgroundColor: "#0084d1",
                            justifyContent: "center",
                            alignItems: "center",
                            border: `${ZeplinPxToMM(4)}px solid #0084d1`,
                          }}
                        >
                          <Pstyled3>{"착불"}</Pstyled3>
                        </FlexDiv>
                        <FlexDiv
                          style={{ height: "100%", border: `${ZeplinPxToMM(4)}px solid #0084d1`, justifyContent: "center", alignItems: "center" }}
                        ></FlexDiv>
                      </FlexDiv>
                      <div
                        style={{
                          width: ZeplinPxToMM(3),
                          backgroundColor: "#fff",
                          borderTop: `${ZeplinPxToMM(4)}px solid #0084d1`,
                          borderBottom: `${ZeplinPxToMM(4)}px solid #0084d1`,
                        }}
                      ></div>
                      <FlexDiv direction={"column"} grow={1}>
                        <FlexDiv
                          style={{
                            minHeight: ZeplinPxToMM(58),
                            backgroundColor: "#0084d1",
                            justifyContent: "center",
                            alignItems: "center",
                            border: `${ZeplinPxToMM(4)}px solid #0084d1`,
                            borderTopRightRadius: `${ZeplinPxToMM(20)}px`,
                          }}
                        >
                          <Pstyled3>{"선불"}</Pstyled3>
                        </FlexDiv>
                        <FlexDiv
                          style={{
                            height: "100%",
                            border: `${ZeplinPxToMM(4)}px solid #0084d1`,
                            borderBottomRightRadius: `${ZeplinPxToMM(20)}px`,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></FlexDiv>
                      </FlexDiv>
                    </FlexSection>
                  </FlexDiv>
                </FlexSection>

                {/* 로고, 푸터, */}
                <FlexSection style={{ height: `100%` }}>
                  <FlexDiv ml={ZeplinPxToMM(30)} justify={"center"} alignItems={"center"}>
                    <img src={logo} alt={"로고"} style={{ width: ZeplinPxToMM(458), height: ZeplinPxToMM(87) }}></img>
                  </FlexDiv>
                  <FlexDiv ml={ZeplinPxToMM(30)} justify={"center"} alignItems={"center"}>
                    <img src={logo2} alt={"로고"} style={{ width: ZeplinPxToMM(338), height: ZeplinPxToMM(83) }}></img>
                  </FlexDiv>
                </FlexSection>
              </>
            )}
          </FlexDiv>
        </FlexDiv>
      ))}
    </Container>
  )
}
const TableStyle1 = (props) => {
  const { name, style, lineHeight, fontSize } = props
  const { children } = props
  return (
    <FlexSection
      style={{
        marginLeft: ZeplinPxToMM(32),
        marginRight: ZeplinPxToMM(32),
        ...style,
      }}
    >
      <FlexDiv
        style={{
          width: `${ZeplinPxToMM(92)}px`,
          minWidth: `${ZeplinPxToMM(92)}px`,
          backgroundColor: "#0084d1",
          justifyContent: "center",
          alignItems: "center",
          border: `${ZeplinPxToMM(4)}px solid #0084d1`,
          borderRight: "none",
          borderTopLeftRadius: `${ZeplinPxToMM(20)}px`,
          borderBottomLeftRadius: `${ZeplinPxToMM(20)}px`,
        }}
      >
        <Pstyled3 style={{ whiteSpace: "pre", fontSize: fontSize, lineHeight: lineHeight }}>{name}</Pstyled3>
      </FlexDiv>
      <FlexDiv
        direction={"column"}
        style={{
          width: "100%",
          height: "100%",
          border: `${ZeplinPxToMM(4)}px solid #0084d1`,
          borderLeft: "none",
          borderTopRightRadius: `${ZeplinPxToMM(20)}px`,
          borderBottomRightRadius: `${ZeplinPxToMM(20)}px`,
        }}
      >
        {children}
      </FlexDiv>
    </FlexSection>
  )
}

export default CustomerContainer(PrintFreight)

const Circle = styled.div`
  border: ${ZeplinPxToMM(8)}px solid #0084d1;
  border-radius: 50%;
  width: ${ZeplinPxToMM(70)}px;
  height: ${ZeplinPxToMM(70)}px;
`
const Container = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
const FlexSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: ${(props) => props.ml || "0"}px;
  margin-right: ${(props) => props.mr || "0"}px;
  margin-top: ${(props) => props.mt || "0"}px;
  margin-bottom: ${(props) => props.mb || "0"}px;

  padding-left: ${(props) => props.pl || "0"}px;
  padding-right: ${(props) => props.pr || "0"}px;
  padding-top: ${(props) => props.pt || "0"}px;
  padding-bottom: ${(props) => props.pb || "0"}px;
`
const Pstyled1 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(50)}px;
  line-height: ${ZeplinPxToMM(60)}px;
  color: #000;
`
const Pstyled2 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(33.3)}px;
  line-height: ${ZeplinPxToMM(40)}px;
  white-space: pre;
  color: #0066a2;
`

const Pstyled3 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(37.5)}px;
  line-height: ${ZeplinPxToMM(39)}px;
  color: #fff;
`

const Pstyled4 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(29.2)}px;
  line-height: ${ZeplinPxToMM(35)}px;
  color: #0066a2;
`

const Pstyled5 = styled.p`
  font-family: "Noto Sans KR";
  font-size: ${ZeplinPxToMM(22.9)}px;
  line-height: ${ZeplinPxToMM(33.3)}px;
  color: #0066a2;
`

const Pstyled6 = styled.p`
  font-family: "Noto Sans KR";
  font-size: ${ZeplinPxToMM(21)}px;
  line-height: ${ZeplinPxToMM(33.3)}px;
  color: #000;
`
const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.alignItems || "normal"};

  flex: ${(props) => props.flex || "0 1 auto"};

  flex-grow: ${(props) => props.grow || "0"};
  flex-shrink: ${(props) => props.shrink || "1"};
  flex-basis: ${(props) => props.basis || "auto"};

  margin-left: ${(props) => props.ml || "0"}px;
  margin-right: ${(props) => props.mr || "0"}px;
  margin-top: ${(props) => props.mt || "0"}px;
  margin-bottom: ${(props) => props.mb || "0"}px;

  padding-left: ${(props) => props.pl || "0"}px;
  padding-right: ${(props) => props.pr || "0"}px;
  padding-top: ${(props) => props.pt || "0"}px;
  padding-bottom: ${(props) => props.pb || "0"}px;
`

const Real = styled.div`
  position: absolute;
`
