import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import SEO from "../seo"
import CustomerContainer from "../../containers/customerContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Category from "../component/common/category"
import Pagination from "../component/common/pagination"
import DatePicker from "../component/common/datePicker"
import Search from "../component/common/search"
import Button from "../component/common/button"
import CheckBox from "../component/common/checkbox"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"

const ReceiptDocument = (props) => {
  const {
    getDocuments,
    documents = [],
    documentsTotal,
    initDocument,
    getDocument,
    getReceiptDocuDownloadExcel,
    setDocumentPrint,
    postDocumentsUpload,
    postFreightsUpload,
  } = props
  const [check, setCheck] = useState([])
  const [tab, setTab] = useState(0) //탭
  const [startDate, setStartDate] = useState(moment().startOf("months"))
  const [endDate, setEndDate] = useState(moment().endOf("months"))
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [current, setCurrent] = useState(1)
  const [popup, setPopup] = useState({ open: false, message: "" })
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "날짜를 다시 확인해주세요.", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const excelUpload = useRef()
  //on Click...
  const onClickCheck = async (seq) => {
    const temp = [...check]
    if (temp.includes(seq)) {
      temp.splice(temp.indexOf(seq), 1)
    } else {
      temp.push(seq)
    }
    setCheck(temp)
  }

  const onClickLookUp = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setPopup({ open: true, message: "날짜를 다시 확인해주세요" })
      return
    }
    let temp = { page: 0, count: 20, startDate: moment(startDate).format("YYYY-MM-DD"), endDate: moment(endDate).format("YYYY-MM-DD") }
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getDocuments({ ...temp })
    setCurrent(1)
  }

  const onClickSearch = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setPopup({ open: true, message: "날짜를 다시 확인해주세요" })
      return
    }
    if (searchIndex === 0) {
      setPopup({ open: true, message: "검색항목을 입력하세요" })
      return
    } 
    // else if (searchText.length < 2) {
    //   setPopup({ open: true, message: "검색어를 두자이상 입력해주세요" })
    //   return
    // }

    let temp = { page: 0, count: 20, startDate: moment(startDate).format("YYYY-MM-DD"), endDate: moment(endDate).format("YYYY-MM-DD") }
    
    // if (searchText.length >= 2 && searchIndex !== 0) {
    if (searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText.length > 0 ? searchText : '' }
    }
    await getDocuments({ ...temp })
    setCurrent(1)
  }

  const onClickExcelSave = async () => {
    let temp = { startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD") }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getReceiptDocuDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "접수문서리스트" })
  }

  const onClickExcelRegister = async () => {
    excelUpload.current.click()
  }

  const onClickRegister = async () => {
    await initDocument()
    navigate("/app/receiptDocumentNew")
  }
  const onClickPrint = () => {
    if (check.length === 0) return
    setDocumentPrint(documents.filter((t) => check.includes(t.docSeq)))
    navigate("/app/printDocu")
  }
  //end onClick...

  const tabCallback = (value) => {
    if (value === 0) return
    navigate("/app/receiptFreight")
  }

  const getData = async () => {
    let temp = { page: current - 1, count: 20, startDate: moment(startDate).format("YYYY-MM-DD"), endDate: moment(endDate).format("YYYY-MM-DD") }
    if (searchText.length < 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getDocuments({ ...temp })
  }

  useEffect(() => {
    getData()
  }, [current])

  return (
    <Layout navi={"접수"}>
      <SEO title={"접수"} />
      <BasicPopup
        open={popup.open}
        onClose={(value) => {
          if (value === "확인") {
            setPopup({ open: false, message: "" })
          }
        }}
        content={popup.message}
        selectedValue={["확인"]}
      />
      <section>
        <PageName title={["접수"]}></PageName>
        <Category items={["문서", "소화물"]} state={tab} setState={tabCallback}></Category>
        <Box justifyContent={"space-between"}>
          <Box>
            <DatePicker
              label={"접수일자"}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onClick={onClickLookUp}
            />
            <Search
              item={[
                "선택",
                "운송장번호",
                "발신거래처지점명",
                "발신인",
                "수신거래처지점명",
                "수신인",
                "수신주소",
                "발신지사",
                "수신지사",
                "품명",
                "크기",
              ]}
              index={searchIndex}
              setIndex={setSearchIndex}
              text={searchText}
              setText={setSearchText}
              onClickSearch={onClickSearch}
            />
          </Box>
          <Box>
            <Button onClick={onClickPrint}>선택송장출력</Button>
            <Button marginLeft={6} onClick={onClickExcelSave}>
              엑셀 저장
            </Button>
            <input
              ref={excelUpload}
              style={{ display: "none" }}
              type={"file"}
              onChange={async (e) => {
                const form_data = new FormData()
                form_data.append("file", e.target.files[0])
                const result = await postDocumentsUpload(form_data)
                if (result.code === 0) {
                  setPopup({ open: true, message: `엑셀등록을 성공하였습니다` })
                  getData()
                } else {
                  setPopup({ open: true, message: `엑셀등록을 실패하였습니다\n${result.message}` })
                }
              }}
              accept={".xlsx"}
            />
            <Button marginLeft={6} onClick={onClickExcelRegister}>
              엑셀 등록
            </Button>
            <Button marginLeft={6} onClick={onClickRegister}>
              등록
            </Button>
          </Box>
        </Box>
      </section>

      <section style={{ marginTop: "21px" }}>
        <table style={{ marginBottom: 35.4 }}>
          <thead>
            <tr>
              <th>선택</th>
              <th>번호</th>
              <th>송장번호</th>
              <th>발신 거래처 지점명</th>
              <th>발신인</th>
              <th>수신 거래처 지점명</th>
              <th>수신인</th>
              <th>수신주소</th>
              <th>발신 지사</th>
              <th>수신 지사</th>
              <th>접수일자</th>
              <th>내용물</th>
              <th>수량</th>
            </tr>
          </thead>
          <tbody>
            {documents
              .sort((a, b) => new Date(b.regDatetime) - new Date(a.regDatetime))
              .map((v, i) => (
                <tr key={i}>
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CheckBox label="" value={check.includes(v.docSeq)} setValue={() => onClickCheck(v.docSeq)} />
                    </div>
                  </td>
                  <td>{documentsTotal - (current - 1) * 20 - i}</td>
                  <td>
                    <button
                      onClick={async () => {
                        await getDocument({ docSeq: v.docSeq })
                        navigate("/app/receiptDocumentNew")
                      }}
                    >
                      <u>{v.docNo}</u>
                    </button>
                  </td>
                  <td>{v.sendPoint.pointName}</td>
                  <td>{v.sendEmpName}</td>
                  <td>{v.recvPoint.pointName}</td>
                  <td>{v.recvEmpName}</td>
                  <td>
                    {v.recvAddr}&nbsp;{v.recvAddrDetail}
                  </td>
                  <td>{v.sendBranch.branchName}</td>
                  <td>{v.recvBranch.branchName}</td>
                  <td>{moment(v.regDatetime).format("YYYY-MM-DD")}</td>
                  <td>{v.content}</td>
                  <td>{Number(v.qty.toString().replace(/,/g, "")).toLocaleString()}</td>
                </tr>
              ))}
          </tbody>
        </table>

        <Pagination current={current} setCurrent={setCurrent} total={documentsTotal} />
      </section>
    </Layout>
  )
}

export default CustomerContainer(ReceiptDocument)

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
