import React, { useEffect, useState } from "react"
import Layout from "../component/common/layout"
import styled from "styled-components"
import SEO from "../seo"
import moment from "moment"
import { useBarcode } from "react-barcodes"
import arrow from "../../images/print-arrow.png"
import logo from "../../images/print-logo.png"
import logo2 from "../../images/logo2.png"
import mobile from "../../images/print-mobile.png"
// import ReceiptContainer from "../../containers/receiptContainer"
import CustomerContainer from "../../containers/customerContainer"

//150 x 100 mm
const ZeplinPxToMM = (px) => {
  return px * 0.320118
}

const printFreightA4 = (props) => {
  
  // const { _freightPrint, initFreightPrint, _documentPrint, initDocumentPrint } = props
  const { freightPrint, initFreightPrint, getDrivers, drivers } = props

  const [target, setTarget] = useState(props.location.state.target)
  const today = moment()
  const generateBarcode = (num) => {
    const { inputRef } = useBarcode({
      value: num,
      options: {
        font: "Noto Sans KR",
        fontOptions: "bold",
        fontSize: `${ZeplinPxToMM(45)}px`,
        textMargin: 0,
        height: ZeplinPxToMM(100),
        margin: 0,
        width: ZeplinPxToMM(5.5),
      },
    })
    return <img ref={inputRef}></img>
  }

  const on = false

  return (
    <Container style={{ overflowY: "scroll", width: "100%", height: "100%" }}>
      <div style={{
        width: '320mm', //height: '210mm', 
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: "flex-start",
        padding: '0',
        border: '0px solid blue',
        marginLeft: '6mm',
        marginTop: '5mm',
      }}>
      {freightPrint.map((v, i) => (
          <>
          <FlexDiv
            key={i}
            style={{
              minWidth: `140mm`,
              maxWidth: "140mm",
              minHeight: `99mm`,
              maxHeight: "99mm",
              // pageBreakAfter: "always",
              marginTop: `${ZeplinPxToMM(10)}px`,
              border: '0px solid red'
            }}
          >
            {/* 왼쪽 페이지*/}
            <FlexDiv
              direction={"column"}
              style={{ position: "relative", width: ZeplinPxToMM(885), height: "100%", borderRight: `${ZeplinPxToMM(0)}px solid #000` }}
            >
              <Real>
                <Pstyled1 style={{ position: "absolute", top: 14, left: 125, width: 150, letterSpacing: ZeplinPxToMM(3), textAlign: "center" }}>
                  {v.freightNo}
                </Pstyled1>
                <Pstyled2 style={{ position: "absolute", top: 42, left: 140, width: 130, whiteSpace: "pre" }}>{`  ${today.format(
                  "YY"
                )}        ${today.format("MM")}        ${today.format("DD")} `}</Pstyled2>
                <div
                  style={{
                    position: "absolute",
                    top: 65,
                    left: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: `${ZeplinPxToMM(885)}px`,
                  }}
                >
                  {generateBarcode(v.freightNo)}
                </div>
                <Pstyled2
                  style={{ position: "absolute", top: 125, left: 85, width: 180, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
                >
                  {v.recvTeleNo}
                </Pstyled2>
                <Pstyled2
                  style={{
                    position: "absolute",
                    top: 175,
                    left: 85,
                    width: 180,
                    color: "#000",
                    fontSize: ZeplinPxToMM(25),
                    whiteSpace: "pre-wrap",
                    textAlign: "center",
                  }}
                >
                  {v.recvAddr}
                </Pstyled2>
                <Pstyled2
                  style={{
                    position: "absolute",
                    top: 190,
                    left: 85,
                    width: 180,
                    color: "#000",
                    fontSize: ZeplinPxToMM(25),
                    whiteSpace: "pre-wrap",
                    textAlign: "center",
                  }}
                >
                  {v.recvAddrDetail}
                </Pstyled2>
                <Pstyled2
                  style={{ position: "absolute", top: 223, left: 85, width: 180, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
                >
                  {v.recvEmpName}
                </Pstyled2>
                <Pstyled2
                  style={{ position: "absolute", top: 265, left: 85, width: 180, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
                >
                  {v.recvPoint.driver ? v.recvPoint.driver.driverName : ""}
                </Pstyled2>
                <Pstyled2
                  style={{ position: "absolute", top: 293, left: 85, width: 180, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
                >
                  {v.recvPoint.driver ? v.recvPoint.driver.phoneNo : ""}
                </Pstyled2>
                <div
                  style={{
                    position: "absolute",
                    top: 325,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: `${ZeplinPxToMM(885)}px`,
                  }}
                >
                  {generateBarcode(v.freightNo)}
                </div>
              </Real>
              
            </FlexDiv>

            {/* 오른쪽 페이지*/}
            <FlexDiv
              direction={"column"}
              grow={1}
              style={{ position: "relative", minWidth: ZeplinPxToMM(885), maxWidth: ZeplinPxToMM(885), height: "100%" }}
            >
              <Real style={{marginLeft: '6mm'}}>
                <Pstyled2
                  style={{ position: "absolute", top: 25, left: 60, width: 75, color: "#000", fontSize: ZeplinPxToMM(60), whiteSpace: "pre-wrap" }}
                >
                  {v.sendBranch.branchName}
                </Pstyled2>
                <Pstyled2
                  style={{ position: "absolute", top: 25, left: 213, width: 75, color: "#000", fontSize: ZeplinPxToMM(60), whiteSpace: "pre-wrap" }}
                >
                  {v.recvBranch.branchName}
                </Pstyled2>
                <Pstyled2
                  style={{ position: "absolute", top: 70, left: 75, width: 200, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
                >
                  {v.sendTeleNo}
                </Pstyled2>
                <Pstyled2
                  style={{ position: "absolute", top: 98, left: 75, width: 200, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
                >
                  {`${v.sendPoint.customer.custName} / ${v.sendPoint.pointName}`}
                </Pstyled2>
                <Pstyled2
                  style={{ position: "absolute", top: 127, left: 75, width: 200, color: "#000", fontSize: ZeplinPxToMM(25), textAlign: "center" }}
                >
                  {v.sendEmpName}
                </Pstyled2>
                <div
                  style={{
                    position: "absolute",
                    top: 157,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: `${ZeplinPxToMM(885)}px`,
                  }}
                >
                  {generateBarcode(v.freightNo)}
                </div>
                <Pstyled2
                  style={{ position: "absolute", top: 230, left: 50, width: 200, color: "#000", fontSize: ZeplinPxToMM(25), whiteSpace: "pre-wrap" }}
                >{`${v.remark ? v.remark : "없음"} / ${v.itemName}`}</Pstyled2>

                <div style={{ position: "absolute", top: 305, left: 160, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {v.payType === 1 && <Circle />}
                </div>
                <div style={{ position: "absolute", top: 305, left: 195, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {v.payType === 2 && <Circle />}
                </div>
                <div style={{ position: "absolute", top: 305, left: 235, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {v.payType === 3 && <Circle />}
                </div>
              </Real>

            </FlexDiv>

            
          </FlexDiv>
          {(i+1)%4 == 0 && <div style={{width: '320mm', height: '10mm', }}></div>}
          </>
      ))}
      </div>
    </Container>
  )
}
const Circle = styled.div`
  border: ${ZeplinPxToMM(8)}px solid #0084d1;
  border-radius: 50%;
  width: ${ZeplinPxToMM(70)}px;
  height: ${ZeplinPxToMM(70)}px;
`
const Container = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
const TableStyle1 = (props) => {
  const { name, style, lineHeight, fontSize } = props
  const { children } = props
  return (
    <FlexSection
      style={{
        marginLeft: ZeplinPxToMM(32),
        marginRight: ZeplinPxToMM(32),
        ...style,
      }}
    >
      <FlexDiv
        style={{
          width: `${ZeplinPxToMM(92)}px`,
          minWidth: `${ZeplinPxToMM(92)}px`,
          backgroundColor: "#0084d1",
          justifyContent: "center",
          alignItems: "center",
          border: `${ZeplinPxToMM(4)}px solid #0084d1`,
          borderRight: "none",
          borderTopLeftRadius: `${ZeplinPxToMM(20)}px`,
          borderBottomLeftRadius: `${ZeplinPxToMM(20)}px`,
        }}
      >
        <Pstyled3 style={{ whiteSpace: "pre", fontSize: fontSize, lineHeight: lineHeight }}>{name}</Pstyled3>
      </FlexDiv>
      <FlexDiv
        direction={"column"}
        style={{
          width: "100%",
          height: "100%",
          border: `${ZeplinPxToMM(4)}px solid #0084d1`,
          borderLeft: "none",
          borderTopRightRadius: `${ZeplinPxToMM(20)}px`,
          borderBottomRightRadius: `${ZeplinPxToMM(20)}px`,
        }}
      >
        {children}
      </FlexDiv>
    </FlexSection>
  )
}

export default CustomerContainer(printFreightA4)

const FlexSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: ${(props) => props.ml || "0"}px;
  margin-right: ${(props) => props.mr || "0"}px;
  margin-top: ${(props) => props.mt || "0"}px;
  margin-bottom: ${(props) => props.mb || "0"}px;

  padding-left: ${(props) => props.pl || "0"}px;
  padding-right: ${(props) => props.pr || "0"}px;
  padding-top: ${(props) => props.pt || "0"}px;
  padding-bottom: ${(props) => props.pb || "0"}px;
`
const Pstyled1 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(50)}px;
  line-height: ${ZeplinPxToMM(60)}px;
  color: #000;
`
const Pstyled2 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(33.3)}px;
  line-height: ${ZeplinPxToMM(40)}px;
  white-space: pre;
  color: #0066a2;
`

const Pstyled3 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(37.5)}px;
  line-height: ${ZeplinPxToMM(39)}px;
  color: #fff;
`

const Pstyled4 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(29.2)}px;
  line-height: ${ZeplinPxToMM(35)}px;
  color: #0066a2;
`

const Pstyled5 = styled.p`
  font-family: "Noto Sans KR";
  font-size: ${ZeplinPxToMM(22.9)}px;
  line-height: ${ZeplinPxToMM(33.3)}px;
  color: #0066a2;
`

const Pstyled6 = styled.p`
  font-family: "Noto Sans KR";
  font-size: ${ZeplinPxToMM(21)}px;
  line-height: ${ZeplinPxToMM(33.3)}px;
  color: #000;
`
const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.alignItems || "normal"};

  flex: ${(props) => props.flex || "0 1 auto"};

  flex-grow: ${(props) => props.grow || "0"};
  flex-shrink: ${(props) => props.shrink || "1"};
  flex-basis: ${(props) => props.basis || "auto"};

  margin-left: ${(props) => props.ml || "0"}px;
  margin-right: ${(props) => props.mr || "0"}px;
  margin-top: ${(props) => props.mt || "0"}px;
  margin-bottom: ${(props) => props.mb || "0"}px;

  padding-left: ${(props) => props.pl || "0"}px;
  padding-right: ${(props) => props.pr || "0"}px;
  padding-top: ${(props) => props.pt || "0"}px;
  padding-bottom: ${(props) => props.pb || "0"}px;
`

const Real = styled.div`
  position: absolute;
`
