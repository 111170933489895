import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../seo"
import CustomerContainer from "../../containers/customerContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import BasicPopup from "../../popups/basicPopup"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import Button from "../component/common/button"
import ZipCodePopup from "../../popups/zipCodePopup"

const ReceiptFreightNew = (props) => {
  const { getInfo, info, freight, branchs, points, postFreight, patchFreight, getPointToBranch, deleteFreight } = props
  //팝업
  const [popup, setPopup] = useState(false)
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)
  const [zipCodePopup, setZipCodePopup] = useState(false)
  //
  const [sendBranch, setSendBranch] = useState({ name: "", seq: -1 })
  const [recvBranch, setRecvBranch] = useState({ name: "", seq: -1 })

  const [sendEmpName, setSendEmpName] = useState("")
  const [sendTeleNo, setSendTeleNo] = useState("") //발신인 전화번호
  const [recvPoint, setRecvPoint] = useState({ name: "선택", seq: -1 })
  const [recvEmpName, setRecvEmpName] = useState("")
  const [recvTeleNo, setRecvTeleNo] = useState("")
  //
  const [addr, setAddr] = useState("") //수신주소
  const [addrDetail, setAddrDetail] = useState("")
  const [zipCode, setZipCode] = useState("")

  const [itemName, setItemName] = useState("")
  const [qty, setQty] = useState("")
  const [price, setPrice] = useState("0")
  //
  const [size, setSize] = useState({ name: "선택", seq: -1 })
  const [remark, setRemark] = useState("")
  const [title, setTitle] = useState("소화물등록")
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (Object.keys(freight).length === 0) return
    const sendBranchObj = branchs.find((v) => v.branchSeq === freight.sendBranchSeq)
    if (sendBranchObj !== undefined) {
      setSendBranch({ name: sendBranchObj.branchName, seq: sendBranchObj.branchSeq })
    }
    const recvBranchObj = branchs.find((v) => v.branchSeq === freight.recvBranchSeq)
    if (recvBranchObj !== undefined) {
      setRecvBranch({ name: recvBranchObj.branchName, seq: recvBranchObj.branchSeq })
    }

    setSendEmpName(freight.sendEmpName)
    setSendTeleNo(freight.sendTeleNo)
    const pointObj = points.find((v) => v.pointSeq === freight.recvPointSeq)
    setRecvPoint({ name: pointObj ? pointObj.pointName : '', seq: pointObj ? pointObj.pointSeq : 0 })
    setRecvEmpName(freight.recvEmpName)
    setRecvTeleNo(freight.recvTeleNo)
    setAddr(freight.recvAddr)
    setAddrDetail(freight.recvAddrDetail)
    setZipCode(freight.recvZipCode)
    setItemName(freight.itemName)
    setQty(freight.qty)
    setPrice(freight.price.toLocaleString())
    setSize(freight.size === 1 ? { name: "대", seq: 1 } : { name: "소", seq: 2 })
    setRemark(freight.remark)
    setTitle("소화물상세")
    //
  }, [freight])

  useEffect(() => {

    const point = points.filter( row => row.pointSeq == info.pointSeq )
    const branch = branchs.filter( row => row.branchSeq == (point.length > 0 ? point[0].branchSeq : 0) )
    if (branch.length > 0) {
      setSendBranch({ name: branch[0].branchName, seq: branch[0].branchSeq })
    }
    else {
      setSendBranch({ name: "", seq: -1 })
    }
    
  }, [info])
  
  const getData = async () => {
    await getInfo()
  }
  //onClick...
  const onClickRegister = async () => {
    if (
      sendEmpName === "" ||
      // recvPoint.seq === -1 ||
      recvEmpName === "" ||
      recvTeleNo === "" ||
      sendBranch.seq === -1 ||
      recvBranch.seq === -1 ||
      addr === "" ||
      addrDetail === "" ||
      zipCode === "" ||
      itemName === "" ||
      qty === "" ||
      qty === "0" ||
      price === "" ||
      price === "0" ||
      size.seq === -1
    ) {
      setSaveCheckPopup(true)
      return
    }

    let temp = {
      recvPointSeq: recvPoint.seq > 0 ? recvPoint.seq : 0,
      sendEmpName: sendEmpName,
      sendTeleNo: sendTeleNo,
      recvEmpName: recvEmpName,
      recvTeleNo: recvTeleNo,
      sendBranchSeq: sendBranch.seq,
      recvBranchSeq: recvBranch.seq,
      recvAddr: addr, //수신주소
      recvAddrDetail: addrDetail, //수신상세주소
      recvZipCode: zipCode, //수신우편번호
      itemName: itemName,
      qty: qty.toString().replace(/,/g, ""),
      price: price.toString().replace(/,/g, ""),
      payType: 1,
      size: size.seq,
      remark: remark,
    }
    if (Object.keys(freight).length === 0) {
      console.log("등록")
      await postFreight({ ...temp })
    } else {
      console.log("수정")
      await patchFreight({
        freightSeq: freight.freightSeq,
        ...temp,
      })
    }
    navigate("/app/receiptFreight")
  }
  const onClickDelete = async () => {
    await deleteFreight({ freightSeq: freight.freightSeq })
    navigate("/app/receiptFreight")
  }
  const onClickCancel = () => {
    navigate("/app/receiptFreight")
  }
  //end onClick...

  const onSelectAddr = async ( value ) => {

    // console.log(111,value,_points)

    setAddr(value)

    let point = [] 
    
    // if ( senderCustomer.seq === -1 ) {
      
    //   point = points.filter( row => row.addr.trim() == value )

    // }
    // else {

      // const custPoint = points.filter( row => row.pointSeq === senderCustomer.seq )

      // if ( custPoint.length > 0 ) {
        
        point = points.filter( row => row.custSeq == (info && info.custSeq) && row.addr.trim() == value )

      // } // end if 

    // } // end if 

    if ( point.length > 0 ) {

      // console.log( 777, value, point ) // 경기 고양시 일산동구 장항동 615-3

      onSelectRecv( { seq : point[0].pointSeq, name : point[0].pointName } )

    } 
    else {

      setRecvBranch({ name: 'CJ', seq: 230 })

    } // end if 

  }

  const onSelectRecv = async (value) => {

    // const result = await getPointToBranch({
    //   sendPointSeq: info && info.pointSeq,
    //   recvPointSeq: value.seq,
    // })
    // if (result.send && result.recv) {
    //   setSendBranch({ name: result.send.branchName, seq: result.send.branchSeq })
    //   setRecvBranch({ name: result.recv.branchName, seq: result.recv.branchSeq })
    // } else {
    //   setSendBranch({ name: "", seq: -1 })
    //   setRecvBranch({ name: "", seq: -1 })
    // }

    const point = points.filter( row => row.pointSeq == value.seq )
    const branch = branchs.filter( row => row.branchSeq == (point.length > 0 ? point[0].branchSeq : 0) )
    if (branch.length > 0) {
      setRecvBranch({ name: branch[0].branchName, seq: branch[0].branchSeq })
    }
    else {
      setRecvBranch({ name: "", seq: -1 })
    }

    setRecvPoint(value)

    // const point = points.filter( row => row.pointSeq == value.seq )

    if ( point.length > 0 ) {

      console.log(123,point)

      setAddr( point[0].addr ) // 서울 중구 남대문로5가 500 / 605 
      setAddrDetail( point[0].addrDetail )
      setZipCode( point[0].zipCode )

    } 
    else { 
      
      setAddr( "" )
      setAddrDetail( "" )
      setZipCode( "" )
      
    } // end if 

  }

  return (
    <Layout navi={"접수"}>
      <SEO title={"접수"} />
      <div style={{ marginBottom: "30px" }}>
        <PageName title={["접수", title]}></PageName>
      </div>
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <BasicPopup
        open={popup}
        onClose={(value) => {
          if (value === "확인") {
            onClickDelete()
          }
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <ZipCodePopup open={zipCodePopup} onClose={() => setZipCodePopup(false)} setAddr={onSelectAddr} setZipCode={setZipCode} />
      {Object.keys(freight).length !== 0 && (
        <Button onClick={() => console.log("택배조회")} style={{ marginBottom: "15px" }}>
          CJ 택배조회 바로가기
        </Button>
      )}
      <Section>
        <InputBox readOnly={true} label="운송장번호" value={freight.freightNo} />
        <InputBox readOnly={true} marginLeft={12} label="발신거래처지점명" value={info && info.pointName} />
        <InputBox marginLeft={12} label="발신인" value={sendEmpName} setValue={setSendEmpName} />
        <InputBox marginLeft={12} label="발신인전화번호" value={sendTeleNo} setValue={setSendTeleNo} />
        <InputBox
          marginLeft={12}
          type={"select"}
          label="수신거래처지점명"
          item={[{seq: -1, name: "선택"}].concat(points.filter((i) => i.custSeq === (info && info.custSeq)).map((v) => ({ name: v.pointName, seq: v.pointSeq })))}
          value={recvPoint.name}
          setValue={onSelectRecv}
        />
        <InputBox marginLeft={12} label="수신인" value={recvEmpName} setValue={setRecvEmpName} />
      </Section>
      <Splitline></Splitline>
      <Section>
        <InputBox label="수신인전화번호" value={recvTeleNo} setValue={setRecvTeleNo} />
        <InputBox marginLeft={12} readOnly={true} label="발신지사" value={sendBranch.name} setValue={(value) => setSendBranch(value)} />
        <InputBox readOnly={true} label="수신지사" marginLeft={12} value={recvBranch.name} setValue={(value) => setRecvBranch(value)} />
        <InputBox type={"address"} label="수신주소" marginLeft={12} value={addr} setValue={onSelectAddr} onClick={() => setZipCodePopup(true)} />
        <InputBox label="상세주소" marginLeft={12} value={addrDetail} setValue={setAddrDetail} />
        <InputBox readOnly={true} label="우편번호" marginLeft={12} value={zipCode} setValue={setZipCode} />
      </Section>
      <Splitline></Splitline>
      <Section>
        <InputBox label="품명" value={itemName} setValue={setItemName} />
        <InputBox marginLeft={12} type={"qty"} label="수량" value={Number(qty.toString().replace(/,/g, "")).toLocaleString()} setValue={setQty} />
        <InputBox
          marginLeft={12}
          type={"price"}
          label="단가"
          value={Number(price.toString().replace(/,/g, "")).toLocaleString()}
          setValue={setPrice}
        />
      </Section>
      <Splitline></Splitline>
      <Section>
        <InputBox
          type={"select"}
          label="크기"
          item={[
            { name: "소", seq: "2" },
            { name: "대", seq: "1" },
          ]}
          value={size.name}
          setValue={(value) => setSize(value)}
        />
        <InputBox readOnly={true} marginLeft={12} label="신용/착불/선불" value={"신용"} />
        <InputBox marginLeft={12} label="비고" fullWidth={true} value={remark} setValue={setRemark} />
      </Section>
      <Splitline></Splitline>

      <Section id="FotterButton">
        {Object.keys(freight).length === 0 ? (
          <FotterButton onClickCancel={onClickCancel} onClickSave={onClickRegister}></FotterButton>
        ) : (
          <FotterButton
            onClickCancel={onClickCancel}
            onClickSave={onClickRegister}
            onClickDelete={() => {
              setPopup(true)
            }}
          ></FotterButton>
        )}
      </Section>
    </Layout>
  )
}

export default CustomerContainer(ReceiptFreightNew)

const Section = styled.section`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`
const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: 17px;
  margin-bottom: 17px;
  background-color: #fafafb;
`
