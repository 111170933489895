export const OnClickExcelSave = (props) => {
  const { result, fileName } = props
  const blob = new Blob([result], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
  })

  const link = document.createElement("a")
  const href = window.URL.createObjectURL(blob)

  link.href = href
  link.download = `${fileName}.xlsx`
  link.click()
}

export const GetStatus = (num) => {
  const arr = ["지사 발신", "옥천 상차", "한통 발신", "한통 수신", "배송완료", "한통 픽업", "한통 분류", "옥천 상차", "옥천 하차", "지사 픽업"]
  return arr[num - 1]
}
