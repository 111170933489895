import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import SEO from "../seo"
import CustomerContainer from "../../containers/customerContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import { navigate } from "gatsby"
import BasicPopup from "../../popups/basicPopup"
import ZipCodePopup from "../../popups/zipCodePopup"
import cookie from "react-cookies"
const tempList = [
  { seq: 0, name: "운영" },
  { seq: 1, name: "폐쇄" },
]
const MyPage = (props) => {
  const { getInfo, info = {}, getPointCheckId, patchPoint, getDrivers, drivers } = props
  const [pointName, setPointName] = useState("")
  const [teleNo, setTeleNo] = useState("")
  const [faxNo, setFaxNo] = useState("")
  const [empName, setEmpName] = useState("")
  const [deptName, setDeptName] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [email, setEmail] = useState("")
  const [driver, setDriver] = useState({ seq: -1, name: "선택" }) // 배송기사
  const [visitTimes, setVisitTimes] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [opType, setOpType] = useState({ seq: -1, name: "선택" }) // 지점 운영 상태

  const [addr, setAddr] = useState("")
  const [addrDetail, setAddrDetail] = useState("")
  const [openDate, setOpenDate] = useState(moment())
  const [closeDate, setCloseDate] = useState(null)
  const [remark, setRemark] = useState("")

  const [userId, setUserId] = useState("") //아이디
  const [password, setPassword] = useState("") //비밀번호
  const [IdDesc, setIdDesc] = useState(0)
  const IdDescArr = ["", "사용 할 수 있는 아이디 입니다", "사용 할 수 없는 아이디 입니다"]
  const [zipCodePopup, setZipCodePopup] = useState(false)
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)
  //onClick ..
  const onClickCancel = () => {
    navigate("/app/main")
  }
  const onClickSave = async () => {
    if (
      pointName === "" ||
      teleNo === "" ||
      faxNo === "" ||
      empName === "" ||
      email === "" ||
      driver.seq === -1 ||
      visitTimes === "" ||
      addr === "" ||
      addrDetail === "" ||
      IdDesc !== 1 ||
      opType.seq === -1
    ) {
      setSaveCheckPopup(true)
      return
    }
    const result = await patchPoint({
      custSeq: info.custSeq,
      pointName: pointName,
      teleNo: teleNo,
      faxNo: faxNo,
      empName: empName,
      deptName: deptName,
      phoneNo: phoneNo,
      email: email,
      driverSeq: driver.seq,
      visitTimes: visitTimes,
      addr: addr,
      addrDetail: addrDetail,
      zipCode: zipCode,
      opType: opType.seq,
      openDate: moment(openDate).format("YYYY-MM-DD"),
      closeDate: closeDate === null ? null : moment(closeDate).format("YYYY-MM-DD"),
      userId: userId,
      password: password,
      remark: remark,
    })
    await getInfo()
    cookie.save("pointAdminName", empName, { path: "/", maxAge: 3600 * 24 })
    navigate("/app/main")
  }
  //end onClick...
  const getData = async () => {
    await getDrivers()
    await getInfo()
  }
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (Object.keys(info).length === 0) return
    if (drivers.length === 0) return
    setPointName(info.pointName)
    setTeleNo(info.teleNo)
    setFaxNo(info.faxNo)
    setEmpName(info.empName)
    setDeptName(info.deptName)
    setPhoneNo(info.phoneNo)
    setEmail(info.email)
    const t = drivers.find((i) => i.driverSeq === info.driverSeq)
    if (t !== undefined) {
      setDriver({
        seq: t.driverSeq,
        name: t.driverName,
      })
    }
    setVisitTimes(info.visitTimes)
    setZipCode(info.zipCode)
    setOpType({ seq: info.opType, name: tempList.find((i) => i.seq === info.opType).name })
    setAddr(info.addr)
    setAddrDetail(info.addrDetail)
    setOpenDate(info.openDate)
    setCloseDate(info.closeDate)
    setRemark(info.remark)
    setUserId(info.userId)
    setIdDesc(1)
  }, [info, drivers])

  const onFocusIn = () => {
    setIdDesc(0)
  }
  const onFocusOut = async () => {
    const result = await getPointCheckId({ id: userId })
    if (result === 1) {
      setIdDesc(1)
    } else {
      setIdDesc(2)
    }
  }
  return (
    <Layout navi={"내정보"}>
      <SEO title={"내정보"} />
      <ZipCodePopup open={zipCodePopup} onClose={() => setZipCodePopup(false)} setAddr={setAddr} setZipCode={setZipCode} />
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <PageName title={["내정보"]} style={{ marginBottom: "40px" }}></PageName>

      <Section id="InputBox">
        <div style={{ display: "flex" }}>
          <InputBox label="지점코드" readOnly={true} value={info.pointNo}></InputBox>
          <InputBox label="지점명" value={pointName} setValue={setPointName} marginLeft={10}></InputBox>
          <InputBox label="전화번호" value={teleNo} setValue={setTeleNo} marginLeft={10}></InputBox>
          <InputBox label="팩스번호" value={faxNo} setValue={setFaxNo} marginLeft={10}></InputBox>
        </div>
      </Section>
      <Splitline></Splitline>
      <Section id="InputBox">
        <div style={{ display: "flex" }}>
          <InputBox label="담당자" value={empName} setValue={setEmpName}></InputBox>
          <InputBox label="부서명" value={deptName} setValue={setDeptName} marginLeft={10}></InputBox>
          <InputBox label="휴대폰번호" value={phoneNo} setValue={setPhoneNo} marginLeft={10}></InputBox>
          <InputBox label="이메일" value={email} setValue={setEmail} marginLeft={10}></InputBox>
          <InputBox
            type={"select"}
            label="배송기사"
            item={drivers ? drivers.map((i) => ({ seq: i.driverSeq, name: i.driverName })) : []}
            value={driver.name}
            setValue={(value) => setDriver(value)}
            marginLeft={10}
          />
          <InputBox label="방문시간대" value={visitTimes} setValue={setVisitTimes} marginLeft={10}></InputBox>
        </div>
      </Section>
      <Splitline></Splitline>

      <Section id="InputBox">
        <div style={{ display: "flex" }}>
          <InputBox type={"address"} label="주소" value={addr} setValue={setAddr} onClick={() => setZipCodePopup(true)}></InputBox>
          <InputBox label="상세주소" value={addrDetail} setValue={setAddrDetail} marginLeft={10}></InputBox>
          <InputBox label="우편번호" readOnly={true} value={zipCode} setValue={setZipCode} marginLeft={10}></InputBox>
          <InputBox type={"select"} label="지점운영상태" item={tempList} value={opType.name} setValue={setOpType} marginLeft={10} />
          <InputBox type={"date"} label="지점개설일자" value={openDate} setValue={setOpenDate} marginLeft={10} />
          <InputBox type={"date"} label="지점폐쇄일자" noSelectable={true} value={closeDate} setValue={setCloseDate} marginLeft={10} />
        </div>
      </Section>

      <Splitline></Splitline>
      <Section id="InputBox">
        <AlignDiv flex={1}>
          <InputBox fullWidth={true} label="아이디" value={userId} setValue={setUserId} onFocusIn={onFocusIn} onFocusOut={onFocusOut}></InputBox>
          <div style={{ height: "20px" }}>
            <IDdesc isEnable={IdDesc === 1}>{IdDescArr[IdDesc]}</IDdesc>
          </div>
        </AlignDiv>

        <AlignDiv flex={1}>
          <InputBox fullWidth={true} isPassword={true} label="비밀 번호" value={password} setValue={setPassword}></InputBox>
        </AlignDiv>
      </Section>
      <Splitline style={{ marginTop: "10px" }}></Splitline>

      <Section id="InputBox">
        <AlignDiv flex={1}>
          <InputBox fullWidth={true} label="비고" value={remark} setValue={setRemark}></InputBox>
        </AlignDiv>
      </Section>
      <Splitline></Splitline>

      <Section id="FotterButton">
        <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave}></FotterButton>
      </Section>
    </Layout>
  )
}

export default CustomerContainer(MyPage)

const Section = styled.section`
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
`

const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: 20px;
  margin-bottom: 17px;
  background-color: #fafafb;
`

const AlignDiv = styled.div`
  flex: ${(props) => (props.flex ? 1 : 0)};
  margin-left: ${(props) => (props.start ? "0px" : "5px")};
  margin-right: ${(props) => (props.end ? "0px" : "5px")};
`

const IDdesc = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: ${(props) => (props.isEnable ? "#00adee" : "#000")};
`
