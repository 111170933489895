import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import SEO from "../seo"
import CustomerContainer from "../../containers/customerContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import DatePicker from "../component/common/datePicker"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave, GetStatus } from "../../utils/util"
import moreBtn from "../../images/more_btn.png"
const FreightManage = (props) => {
  const { getFreightTracks, freightTracks = [], freightTracksTotal, info, getFreightDownloadExcel } = props
  //scan date
  const [startDate, setStartDate] = useState(moment().startOf("months"))
  const [endDate, setEndDate] = useState(moment().endOf("months"))

  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [current, setCurrent] = useState(1)
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "날짜를 다시 확인해주세요.", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const [signPopup, setSignPopup] = useState({ open: false, imageUrl: null })
  const [remarkNum, setRemarkNum] = useState(-1)
  //onClick ..
  const onClickLookUp = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setMessageIndex(1)
      setPopup(true)
      return
    }
    let temp = {
      page: 0,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getFreightTracks({ ...temp })
    setCurrent(1)
  }

  const onClickSearch = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setMessageIndex(1)
      setPopup(true)
      return
    }
    if (searchIndex === 0) {
      setMessageIndex(2)
      setPopup(true)
      return
    } 
    // else if (searchText.length < 2) {
    //   setMessageIndex(3)
    //   setPopup(true)
    //   return
    // }
    let temp = {
      page: 0,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }

    // if (searchText.length >= 2 && searchIndex !== 0) {
    if (searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText.length > 0 ? searchText : '' }
    }
    await getFreightTracks({ ...temp })
    setCurrent(1)
    setCurrent(1)
  }

  const onClickExcelSave = async () => {
    let temp = {
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getFreightDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "소화물리스트" })
  }
  //end onClick ..

  const getData = async () => {
    let temp = {
      page: current - 1,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getFreightTracks({ ...temp })
  }

  useEffect(() => {
    getData()
  }, [current])

  return (
    <Layout navi={"소화물관리"}>
      <SEO title={"소화물관리"} />
      <BasicPopup
        open={signPopup.open}
        onClose={() => setSignPopup({ open: false, imageUrl: null })}
        imageUrl={signPopup.imageUrl}
        selectedValue={["확인"]}
      />
      <BasicPopup
        open={popup}
        onClose={(value) => {
          if (value === "확인") {
            setPopup(false)
          }
        }}
        content={messageArr[messageIndex]}
        selectedValue={["확인"]}
      />
      <section>
        <PageName title={["소화물관리"]}></PageName>
        <Title>소화물 관리</Title>
        <Box justifyContent={"space-between"}>
          <Box>
            <DatePicker
              label={"스캔일자"}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onClick={onClickLookUp}
            />
            <Search
              item={[
                "선택",
                "운송장번호",
                "발신거래처지점명",
                "발신인",
                "수신거래처지점명",
                "수신인",
                "수신주소",
                "발신지사",
                "도착지사",
                "스캔담당",
                "위치",
                "상태",
              ]}
              index={searchIndex}
              setIndex={setSearchIndex}
              text={searchText}
              setText={setSearchText}
              onClickSearch={onClickSearch}
            />
          </Box>
          <Button onClick={onClickExcelSave}>엑셀 저장</Button>
        </Box>
      </section>

      <section style={{ marginTop: "21px" }}>
        <table style={{ marginBottom: 35.4 }}>
          <thead>
            <tr>
              <th>번호</th>
              <th>운송장번호</th>
              <th>발신 거래처 지점명</th>
              <th>발신인</th>
              <th>수신 거래처 지점명</th>
              <th>수신인</th>
              <th>수신주소</th>
              <th>발신지사</th>
              <th>수신지사</th>
              <th>스캔일자</th>
              <th>스캔담당</th>
              <th>위치</th>
              <th>상태</th>
              <th>크기</th>
              <th>비고</th>
              <th>사진 서명</th>
            </tr>
          </thead>
          <tbody>
            {freightTracks &&
              freightTracks
                .sort((a, b) => new Date(b.regDatetime) - new Date(a.regDatetime))
                .map((v, i) => (
                  <tr key={i}>
                    <td>{freightTracksTotal - (current - 1) * 20 - i}</td>
                    <td>{v.freight.freightNo}</td>
                    <td>{v.freight.sendPoint.pointName}</td>
                    <td>{v.freight.sendEmpName}</td>
                    <td>{v.freight.recvPoint ? v.freight.recvPoint.pointName : ''}</td>
                    <td>{v.freight.recvEmpName}</td>
                    <td>{v.freight.recvAddr}</td>
                    <td>{v.freight.sendBranch.branchName}</td>
                    <td>{v.freight.recvBranch.branchName}</td>
                    <td>{moment(v.regDatetime).format("YYYY-MM-DD")}</td>
                    <td>{v.scanName}</td>
                    <td>
                      {v.addr}&nbsp;{v.addrDetail}
                    </td>
                    <td>{GetStatus(v.status)}</td>
                    <td>{v.freight.size == 1 ? "대" : "소"}</td>
                    <td>
                      <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ width: "100%", height: "calc( 100% - 9px )", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {(v.freight.remark + "").length > 5 ? v.freight.remark.substring(0, 5) + "..." : v.freight.remark}
                        </div>
                        {(v.freight.remark + "").length > 5 && (
                          <div style={{ width: "100%", height: "9px", display: "flex", justifyContent: "flex-end" }}>
                            <button
                              onClick={() => {
                                if (i === remarkNum) setRemarkNum(-1)
                                else setRemarkNum(i)
                              }}
                              onBlur={() => setRemarkNum(-1)}
                              style={{ height: "9px" }}
                            >
                              <img src={moreBtn} alt={"더보기"} style={{ display: "flex" }} />
                            </button>

                            {i === remarkNum && (
                              <div style={{ position: "relative" }}>
                                <div style={{ position: "absolute", left: "-194.5px", top: "9px", zIndex: 10 }}>
                                  <div
                                    style={{
                                      width: "0px",
                                      height: "0px",
                                      borderBottom: "5.6px solid #fff",
                                      borderLeft: "5.65px solid transparent",
                                      borderRight: "5.65px solid transparent",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      backgroundColor: "#fff",
                                      width: "380px",
                                      height: "136px",
                                      overflowY: "auto",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      borderRadius: "4px",
                                      boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
                                      padding: "11px",
                                    }}
                                  >
                                    <p style={{ whiteSpace: "pre-wrap" }}>
                                      <div style={{ width: "100%", height: "100%" }}>
                                        <div
                                          style={{
                                            width: "100%",
                                            height: "calc( 100% - 9px )",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {(v.freight.remark + "").length > 5 ? v.freight.remark.substring(0, 5) + "..." : v.freight.remark}
                                        </div>
                                        {(v.freight.remark + "").length > 5 && (
                                          <div style={{ width: "100%", height: "9px", display: "flex", justifyContent: "flex-end" }}>
                                            <button
                                              onClick={() => {
                                                if (i === remarkNum) setRemarkNum(-1)
                                                else setRemarkNum(i)
                                              }}
                                              onBlur={() => setRemarkNum(-1)}
                                              style={{ height: "9px" }}
                                            >
                                              <img src={moreBtn} alt={"더보기"} style={{ display: "flex" }} />
                                            </button>

                                            {i === remarkNum && (
                                              <div style={{ position: "relative" }}>
                                                <div style={{ position: "absolute", left: "-194.5px", top: "9px", zIndex: 10 }}>
                                                  <div
                                                    style={{
                                                      width: "0px",
                                                      height: "0px",
                                                      borderBottom: "5.6px solid #fff",
                                                      borderLeft: "5.65px solid transparent",
                                                      borderRight: "5.65px solid transparent",
                                                      marginLeft: "auto",
                                                      marginRight: "auto",
                                                    }}
                                                  ></div>
                                                  <div
                                                    style={{
                                                      backgroundColor: "#fff",
                                                      width: "380px",
                                                      height: "136px",
                                                      overflowY: "auto",
                                                      display: "flex",
                                                      justifyContent: "flex-start",
                                                      borderRadius: "4px",
                                                      boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
                                                      padding: "11px",
                                                    }}
                                                  >
                                                    <p style={{ whiteSpace: "pre-wrap" }}>{v.freight.remark}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <Button
                        onClick={() => {
                          setSignPopup({ open: true, imageUrl: v.photoUrl || v.signUrl })
                        }}
                      >
                        확인
                      </Button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        <Pagination current={current} setCurrent={setCurrent} total={freightTracksTotal} />
      </section>
    </Layout>
  )
}

export default CustomerContainer(FreightManage)

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`

const Title = styled.p`
  margin-top: 25px;
  margin-bottom: 25px;
  color: #35363a;
  font-size: 24px;
  font-family: NanumSquareBold;
  line-height: 42px;
  letter-spacing: -0.72px;
`
