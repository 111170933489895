import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Page404 from "./404"
import Root from "../components/page/root"
import Login from "../components/page/login"
import ReceiptDocument from "../components/page/receiptDocument"
import ReceiptFreight from "../components/page/receiptFreight"
import ReceiptDocumentNew from "../components/page/receiptDocumentNew"
import ReceiptFreightNew from "../components/page/receiptFreightNew"
import PouchManage from "../components/page/pouchManage"
import FreightManage from "../components/page/freightManage"
import MyPage from "../components/page/myPage"
import Main from "../components/page/main"
import PrintDocu from "../components/page/printDocu"
import PrintFreight from "../components/page/printFreight"
import PrintFreightA4 from "../components/page/printFreightA4"
import "../components/layout.css"

const App = () => {
  return (
    <Router basepath="/app" style={{ width: "100%", height: "100%" }}>
      {/* 로그인/회원가입 */}
      <Root path="/" />
      <Login path="/login" />
      <PrintDocu path="/printDocu" />
      <PrintFreight path="/printFreight" />
      <PrintFreightA4 path="/printFreightA4" />

      <PrivateRoute path="/main" component={Main} />
      <PrivateRoute path="/receiptDocument" component={ReceiptDocument} />
      <PrivateRoute path="/receiptFreight" component={ReceiptFreight} />
      <PrivateRoute path="/receiptDocumentNew" component={ReceiptDocumentNew} />
      <PrivateRoute path="/receiptFreightNew" component={ReceiptFreightNew} />
      <PrivateRoute path="/pouchManage" component={PouchManage} />
      <PrivateRoute path="/freightManage" component={FreightManage} />
      <PrivateRoute path="/myPage" component={MyPage} />
      {/* 설정 이외의 경로로 들어갈 경우 404 페이지 띄움 */}
      <Page404 path="/*" />
    </Router>
  )
}

export default App
