import React from "react"
import PropTypes from "prop-types"
import DaumPostcode from "react-daum-postcode"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#00000000",
      maxWidth: "500px",
    },
    "& .MuiDialog-paper": {
      margin: "20px",
    },
  },
}))

const ZipCodePopup = (props) => {
  const classes = useStyles()
  const { onClose, open, setAddr = () => {}, setZipCode = () => {} } = props

  const daumPostCode = (data) => {
    let addr = ""
    let extraAddr = ""

    if (data.userSelectedType === "J") {
      addr = data.jibunAddress || data.autoJibunAddress
    } else {
      addr = data.roadAddress || data.autoRoadAddress
    }

    if (data.userSelectedType === "R") {
      // 건물명이 있고, 공동주택일 경우 추가한다.
      if (data.buildingName !== "") {
        extraAddr += data.buildingName
      }
      // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
      if (extraAddr !== "") {
        extraAddr = " (" + extraAddr + ")"
      }
    }

    setAddr(addr + extraAddr)
    setZipCode(data.zonecode)
    onClose()
  }

  return (
    <Dialog className={classes.container} style={{ width: "100%", height: "100%", zIndex: 10000 }} onClose={onClose} open={open} fullWidth={true}>
      <div style={{ margin: 0, padding: 0 }}>
        <DaumPostcode width="100%" height="466" onComplete={daumPostCode} submitMode={false}></DaumPostcode>
      </div>
    </Dialog>
  )
}

ZipCodePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setAddr: PropTypes.func.isRequired,
  setZipCode: PropTypes.func.isRequired,
}

export default ZipCodePopup
