import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { postSignIn } = actions.SignAction
const { getPoints, getBranchs } = actions.CustomerAction
// redux state > component mapping
const mapStateToProps = (state) => ({})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  postSignIn: (param) => dispatch(postSignIn(param)),
  getPoints: (param) => dispatch(getPoints(param)),
  getBranchs: (param) => dispatch(getBranchs(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
