import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import SEO from "../seo"
import CustomerContainer from "../../containers/customerContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Category from "../component/common/category"
import Pagination from "../component/common/pagination"
import DatePicker from "../component/common/datePicker"
import Search from "../component/common/search"
import Button from "../component/common/button"
import CheckBox from "../component/common/checkbox"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"
import moreBtn from "../../images/more_btn.png"

const ReceiptFreight = (props) => {
  const {
    getFreights,
    freights = [],
    getFreight,
    freightsTotal,
    initFreight,
    getReceiptFreightDownloadExcel,
    setFreightPrint,
    postFreightsUpload,
    getBranchList,
    getBranchs,
    
  } = props
  const [check, setCheck] = useState([])
  const [tab, setTab] = useState(1) //탭
  const [startDate, setStartDate] = useState(moment().startOf("months"))
  const [endDate, setEndDate] = useState(moment().endOf("months"))
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [current, setCurrent] = useState(1)
  const [popup, setPopup] = useState({ open: false, message: "" })
  const excelUpload = useRef()
  const [remarkNum, setRemarkNum] = useState(-1)
  //on Click...
  const onClickCheck = async (seq) => {
    const temp = [...check]
    if (temp.includes(seq)) {
      temp.splice(temp.indexOf(seq), 1)
    } else {
      temp.push(seq)
    }
    setCheck(temp)
  }

  const onClickLookUp = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setPopup({ open: true, message: "날짜를 다시 확인해주세요" })
      return
    }
    let temp = {
      page: current - 1,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getFreights({ ...temp })
    setCurrent(1)
  }

  const onClickSearch = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setPopup({ open: true, message: "날짜를 다시 확인해주세요" })
      return
    }
    if (searchIndex === 0) {
      setPopup({ open: true, message: "검색항목을 입력하세요" })
      return
    } 
    // else if (searchText.length < 2) {
    //   setPopup({ open: true, message: "검색어를 두자이상 입력해주세요" })
    //   return
    // }

    let temp = {
      page: current - 1,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }

    // if (searchText.length >= 2 && searchIndex !== 0) {
    if (searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText.length > 0 ? searchText : '' }
    }
    await getFreights({ ...temp })
    setCurrent(1)
  }

  const onClickExcelSave = async () => {
    let temp = {
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getReceiptFreightDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "접수소화물리스트" })
  }

  const onClickExcelRegister = async () => {
    excelUpload.current.click()
  }

  const onClickRegister = async () => {
    await initFreight()
    navigate("/app/receiptFreightNew")
  }
  const onClickPrintA4 = () => {
    if (check.length === 0) return
    setFreightPrint(freights.filter((t) => check.includes(t.freightSeq)))
    navigate("/app/printFreightA4")
  }
  const onClickPrint = () => {
    if (check.length === 0) return
    setFreightPrint(freights.filter((t) => check.includes(t.freightSeq)))
    navigate("/app/printFreight")
  }
  //end onClick...
  const tabCallback = (value) => {
    if (value === 1) return
    navigate("/app/receiptDocument")
  }

  const getData = async () => {
    let temp = {
      page: current - 1,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }
    if (searchText.length >= 2 && searchIndex !== 0) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    
    await getFreights({ ...temp })
    await getBranchs({})

  }

  useEffect(() => {
    getData()
  }, [current])

  return (
    <Layout navi={"접수"}>
      <SEO title={"접수"} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, message: "" })} content={popup.message} selectedValue={["확인"]} />
      <section>
        <PageName title={["접수"]}></PageName>
        <Category items={["문서", "소화물"]} state={tab} setState={tabCallback}></Category>
        <Box justifyContent={"space-between"}>
          <Box>
            <DatePicker
              label={"접수일자"}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onClick={onClickLookUp}
            />
            <Search
              item={[
                "선택",
                "운송장번호",
                "발신거래처지점명",
                "발신인",
                "수신거래처지점명",
                "수신인",
                "수신주소",
                "발신지사",
                "수신지사",
                "품명",
                "크기",
              ]}
              index={searchIndex}
              setIndex={setSearchIndex}
              text={searchText}
              setText={setSearchText}
              onClickSearch={onClickSearch}
            />
          </Box>
          <Box>

            {tab == 1 && <Button onClick={async () => onClickPrintA4()}>선택송장출력(A4)</Button>}

            <Button marginLeft={6} onClick={onClickPrint}>선택송장출력</Button>
            <Button marginLeft={6} onClick={onClickExcelSave}>
              엑셀 저장
            </Button>
            <input
              ref={excelUpload}
              style={{ display: "none" }}
              type={"file"}
              onChange={async (e) => {
                const form_data = new FormData()
                form_data.append("file", e.target.files[0])
                const result = await postFreightsUpload(form_data)
                if (result.code === 0) {
                  setPopup({ open: true, message: `엑셀등록을 성공하였습니다` })
                  getData()
                } else {
                  setPopup({ open: true, message: `엑셀등록을 실패하였습니다\n${result.message}` })
                }
              }}
              accept={".xlsx"}
            />
            <Button marginLeft={6} onClick={onClickExcelRegister}>
              엑셀 등록
            </Button>
            <Button marginLeft={6} onClick={onClickRegister}>
              등록
            </Button>
          </Box>
        </Box>
      </section>

      <section style={{ marginTop: "21px" }}>
        <table style={{ marginBottom: 35.4 }}>
          <thead>
            <tr>
              <th>선택</th>
              <th>번호</th>
              <th>운송장번호</th>
              <th>발신 거래처 지점명</th>
              <th>발신인</th>
              <th>수신 거래처 지점명</th>
              <th>수신인</th>
              <th>수신주소</th>
              <th>발신 지사</th>
              <th>수신 지사</th>
              <th>접수일자</th>
              <th>품명</th>
              <th>단가</th>
              <th>수량</th>
              <th>크기</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            {freights
              .sort((a, b) => new Date(b.regDatetime) - new Date(a.regDatetime))
              .map((v, i) => (
                <tr key={i}>
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CheckBox label="" value={check.includes(v.freightSeq)} setValue={() => onClickCheck(v.freightSeq)} />
                    </div>
                  </td>
                  <td>{freightsTotal - (current - 1) * 20 - i}</td>
                  <td>
                    <button
                      onClick={async () => {
                        await getFreight({ freightSeq: v.freightSeq })
                        navigate("/app/receiptFreightNew")
                      }}
                    >
                      <u>{v.freightNo}</u>
                    </button>
                  </td>
                  <td>{v.sendPoint.pointName}</td>
                  <td>{v.sendEmpName}</td>
                  <td>{v.recvPoint.pointName}</td>
                  <td>{v.recvEmpName}</td>
                  <td>{v.recvAddr}</td>
                  <td>{v.sendBranch.branchName}</td>
                  <td>{v.recvBranch.branchName}</td>
                  <td>{moment(v.regDatetime).format("YYYY-MM-DD")}</td>
                  <td>{v.itemName}</td>
                  <td style={{ textAlign: "right", paddingRight: "5px" }}>{Number(v.price.toString().replace(/,/g, "")).toLocaleString()}</td>
                  <td>{Number(v.qty.toString().replace(/,/g, "")).toLocaleString()}</td>
                  <td>{v.size == 1 ? "대" : "소"}</td>
                  <td>
                    <div style={{ width: "100%", height: "100%" }}>
                      <div style={{ width: "100%", height: "calc( 100% - 9px )", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {(v.remark + "").length > 5 ? v.remark.substring(0, 5) + "..." : v.remark}
                      </div>
                      {(v.remark + "").length > 5 && (
                        <div style={{ width: "100%", height: "9px", display: "flex", justifyContent: "flex-end" }}>
                          <button
                            onClick={() => {
                              if (i === remarkNum) setRemarkNum(-1)
                              else setRemarkNum(i)
                            }}
                            onBlur={() => setRemarkNum(-1)}
                            style={{ height: "9px" }}
                          >
                            <img src={moreBtn} alt={"더보기"} style={{ display: "flex" }} />
                          </button>

                          {i === remarkNum && (
                            <div style={{ position: "relative" }}>
                              <div style={{ position: "absolute", left: "-194.5px", top: "9px", zIndex: 10 }}>
                                <div
                                  style={{
                                    width: "0px",
                                    height: "0px",
                                    borderBottom: "5.6px solid #fff",
                                    borderLeft: "5.65px solid transparent",
                                    borderRight: "5.65px solid transparent",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor: "#fff",
                                    width: "380px",
                                    height: "136px",
                                    overflowY: "auto",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    borderRadius: "4px",
                                    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
                                    padding: "11px",
                                  }}
                                >
                                  <p style={{ whiteSpace: "pre-wrap" }}>{v.remark}</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination current={current} setCurrent={setCurrent} total={freightsTotal} />
      </section>
    </Layout>
  )
}

export default CustomerContainer(ReceiptFreight)

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
