import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import calendarIcon from "../../../images/calendar.png"

import CalendarPopup from "./calendarPopup"
const DatePicker = (props) => {
  const { label, startDate, setStartDate, endDate, setEndDate, onClick = () => {} } = props
  const [calendarPopup, setCalendarPopup] = useState({ open: false, date: null, setDate: () => {} })

  return (
    <Container>
      <CalendarPopup open={calendarPopup.open} date={calendarPopup.date} setDate={calendarPopup.setDate} setCalendarPopup={setCalendarPopup} />
      <Text>{label}</Text>
      <Box
        className={"radius"}
        onClick={() => {
          if (calendarPopup.open) setCalendarPopup({ open: false, date: null, setDate: () => {} })
          else setCalendarPopup({ open: true, date: startDate, setDate: setStartDate })
        }}
        onBlur={() => setCalendarPopup({ open: false, date: null, setDate: () => {} })}
      >
        <img style={{ width: "14px", height: "14px" }} src={calendarIcon} alt={"달력 아이콘"} />
        <DateText>{moment(startDate).format("YYYY.MM.DD")}</DateText>
      </Box>
      <Box
        onClick={() => {
          if (calendarPopup.open) setCalendarPopup({ open: false, date: null, setDate: () => {} })
          else setCalendarPopup({ open: true, date: endDate, setDate: setEndDate })
        }}
        onBlur={() => setCalendarPopup({ open: false, date: null, setDate: () => {} })}
      >
        <img style={{ width: "14px", height: "14px" }} src={calendarIcon} alt={"달력 아이콘"} />
        <DateText>{moment(endDate).format("YYYY.MM.DD")}</DateText>
      </Box>
      <SearchButton onClick={onClick}>조회</SearchButton>
    </Container>
  )
}

export default DatePicker

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 40px;
`
const Box = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  margin-left: -1px;
  border: 1px solid #dbdee5;
  &.radius {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`
const Text = styled.p`
  margin-right: 10px;
  color: #8a8e98;
  font-size: 14px;
  font-family: NanumSquareRegular;
  line-height: 24.5px;
  letter-spacing: -0.42px;
`
const DateText = styled.p`
  margin-left: 10px;
  color: #35363a;
  font-size: 14px;
  font-family: NanumSquareRegular;
  line-height: 24.5px;
`
const SearchButton = styled.button`
  width: 54px;
  height: 40px;
  margin-left: -1px;
  margin-right: 10px;
  border: 1px solid #dbdee5;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #00adee;
  font-size: 14px;
  font-family: NanumSquareBold;
  line-height: 24.5px;
  letter-spacing: -0.42px;
`
