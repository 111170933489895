import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../seo"
import CustomerContainer from "../../containers/customerContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import BasicPopup from "../../popups/basicPopup"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import ZipCodePopup from "../../popups/zipCodePopup"
const ReceiptDocumentNew = (props) => {
  const { patchDocument, postDocument, document, getInfo, info, branchs, points, deleteDocument, getPointToBranch } = props
  const hasKeys = Object.keys(document).length > 0
  const [type, setType] = useState(1) //0:등록, 1:상세
  //팝업
  const [popup, setPopup] = useState(false)

  const [docNo, setDocNo] = useState(hasKeys ? document.docNo : "") //송장번호
  const [sendEmpName, setSendEmpName] = useState(hasKeys ? document.sendEmpName : "") //발신인
  const [recipientName, setRecipientName] = useState(
    hasKeys
      ? {
          name: points.find((v) => v.pointSeq === document.recvPointSeq).pointName,
          seq: points.find((v) => v.pointSeq === document.recvPointSeq).pointSeq,
        }
      : { name: "선택", seq: -1 }
  )
  const [recvEmpName, setRecvEmpName] = useState(hasKeys ? document.recvEmpName : "") //수신인
  //
  const [recvTeleNo, setRecvTeleNo] = useState(hasKeys ? document.recvTeleNo : "") //수신인 전화번호
  const [recvAddr, setRecvAddr] = useState(hasKeys ? document.recvAddr : "")
  const [recvAddrDetail, setRecvAddrDetail] = useState(hasKeys ? document.recvAddrDetail : "")
  const [zipCode, setZipCode] = useState(hasKeys ? document.zipCode : "")
  //
  const [sendBranch, setSendBranch] = useState({ name: "", seq: -1 })
  const [recvBranch, setRecvBranch] = useState({ name: "", seq: -1 })
  const [content, setContent] = useState(hasKeys ? document.content : "")
  const [qty, setQty] = useState(hasKeys ? document.qty : "")
  const [zipCodePopup, setZipCodePopup] = useState(false)
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)
  const [sendPointName, setSendPointName] = useState("")
  const [recvPointList, setRecvPointList] = useState([])
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (recipientName.seq === -1) return
    const temp = points.find((i) => i.pointSeq === recipientName.seq)
    setRecvAddr(temp.addr)
    setRecvAddrDetail(temp.addrDetail)
    setZipCode(temp.zipCode)
  }, [recipientName])

  const getData = async () => {
    await getInfo()
  }
  useEffect(() => {
    if (!info) return
    if (Object.keys(info).length === 0) return
    setSendPointName(info.pointName)
    if (Object.keys(points).length === 0) return
    setRecvPointList(points.filter((i) => i.custSeq === info.custSeq).map((v) => ({ name: v.pointName, seq: v.pointSeq })))
  }, [info, points])
  //onClick...
  useEffect(() => {
    if (Object.keys(info).length === 0) return
    if (recipientName.seq === -1) return
    const temp = async () => {
      const result = await getPointToBranch({
        sendPointSeq: info.pointSeq,
        recvPointSeq: recipientName.seq,
      })
      if (result.send && result.recv) {
        setSendBranch({ name: result.send.branchName, seq: result.send.branchSeq })
        setRecvBranch({ name: result.recv.branchName, seq: result.recv.branchSeq })
      } else {
        setSendBranch({ name: "", seq: -1 })
        setRecvBranch({ name: "", seq: -1 })
      }
    }
    temp()
  }, [info, recipientName])

  const onClickRegister = async () => {
    if (
      sendEmpName === "" ||
      recipientName.seq === -1 ||
      recipientName === "" ||
      recvTeleNo === "" ||
      recvAddr === "" ||
      recvAddrDetail === "" ||
      sendBranch.seq === -1 ||
      recvBranch.seq === -1 ||
      content === "" ||
      qty === ""
    ) {
      setSaveCheckPopup(true)
      return
    }

    const temp = {
      recvPointSeq: recipientName.seq,
      sendEmpName: sendEmpName,
      recvEmpName: recvEmpName,
      recvTeleNo: recvTeleNo,
      recvAddr: recvAddr,
      recvAddrDetail: recvAddrDetail,
      recvZipCode: zipCode,
      sendBranchSeq: sendBranch.seq,
      recvBranchSeq: recvBranch.seq,
      content: content,
      qty: qty,
    }
    if (!hasKeys) {
      await postDocument({ ...temp })
    } else {
      await patchDocument({
        docSeq: document.docSeq,
        ...temp,
      })
    }
    navigate("/app/receiptDocument")
  }
  const onClickDelete = async () => {
    await deleteDocument({ docSeq: document.docSeq })
    navigate("/app/receiptDocument")
  }
  const onClickCancel = () => {
    navigate("/app/receiptDocument")
  }
  //end onClick...
  return (
    <Layout navi={"접수"}>
      <SEO title={"접수"} />
      <ZipCodePopup open={zipCodePopup} onClose={() => setZipCodePopup(false)} setAddr={setRecvAddr} setZipCode={setZipCode} />
      <div style={{ marginBottom: "30px" }}>
        <PageName title={["접수", type === 0 ? "문서등록" : "문서상세"]}></PageName>
      </div>
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <BasicPopup
        open={popup}
        onClose={(value) => {
          if (value === "확인") {
            onClickDelete()
          } else {
            setPopup(false)
          }
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <Section>
        <InputBox readOnly={true} label="송장번호" value={docNo} />
        <InputBox readOnly={true} label="발신 거래처 지점명" marginLeft={12} value={sendPointName} />
        <InputBox label="발신인" marginLeft={12} value={sendEmpName} setValue={setSendEmpName} />
        <InputBox
          type={"select"}
          label="수신 거래처 지점명"
          marginLeft={12}
          item={recvPointList}
          value={recipientName.name}
          setValue={async (value) => {
            setRecipientName(value)
          }}
        />
        <InputBox label="수신인" marginLeft={12} value={recvEmpName} setValue={setRecvEmpName} />
      </Section>
      <Splitline></Splitline>
      <Section>
        <InputBox label="수신인 전화번호" value={recvTeleNo} setValue={setRecvTeleNo} />
        <InputBox type={"address"} label="주소" marginLeft={12} value={recvAddr} setValue={setRecvAddr} onClick={() => setZipCodePopup(true)} />
        <InputBox label="상세주소" marginLeft={12} value={recvAddrDetail} setValue={setRecvAddrDetail} />
        <InputBox readOnly={true} label="우편번호" marginLeft={12} value={zipCode} setValue={setZipCode} />
      </Section>
      <Splitline></Splitline>

      <Section>
        <InputBox readOnly={true} label="발신지사" value={sendBranch.name} />
        <InputBox readOnly={true} label="수신지사" marginLeft={12} value={recvBranch.name} />
        <InputBox label="내용물" marginLeft={12} value={content} setValue={setContent} />
        <InputBox marginLeft={12} type={"qty"} label="수량" value={Number(qty.toString().replace(/,/g, "")).toLocaleString()} setValue={setQty} />
      </Section>
      <Splitline></Splitline>

      <Section id="FotterButton">
        {!hasKeys ? (
          <FotterButton onClickCancel={onClickCancel} onClickSave={onClickRegister}></FotterButton>
        ) : (
          <FotterButton
            onClickCancel={onClickCancel}
            onClickSave={onClickRegister}
            onClickDelete={() => {
              setPopup(true)
            }}
          ></FotterButton>
        )}
      </Section>
    </Layout>
  )
}

export default CustomerContainer(ReceiptDocumentNew)

const Section = styled.section`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`
const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: 17px;
  margin-bottom: 17px;
  background-color: #fafafb;
`
