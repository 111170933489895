import React from "react"
import Layout from "../component/common/layout"
import SEO from "../seo"
import CustomerContainer from "../../containers/customerContainer"
const Menu = (props) => {
  const { info } = props
  return (
    <Layout navi={"메뉴"}>
      <SEO title={"메뉴페이지"} />
    </Layout>
  )
}

export default CustomerContainer(Menu)
