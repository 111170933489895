import React, { useState } from "react"
import moment from "moment"
import styled from "styled-components"

import dropIcon from "../../../images/drop.png"
import calendarIcon from "../../../images/calendar.png"
import searchIcon from "../../../images/search.png"
import CalendarPopup from "./calendarPopup"
const InputBox = (props) => {
  //type: normal, date, select, address
  const { type, label, value, setValue } = props
  // normal
  const { readOnly, fullWidth, marginLeft, onFocusIn, onFocusOut, isPassword } = props
  // date
  const { noSelectable } = props
  const [calendarPopup, setCalendarPopup] = useState({ open: false, date: null, setDate: () => {} })
  // select
  const { item } = props
  const [open, setOpen] = useState(false)
  //address
  const { onClick = () => {} } = props

  const render = () => {
    switch (type) {
      case "date":
        return (
          <Box>
            <CalendarPopup
              open={calendarPopup.open}
              date={calendarPopup.date}
              setDate={calendarPopup.setDate}
              setCalendarPopup={setCalendarPopup}
              noSelectable={noSelectable}
            />
            <Box
              onClick={() => {
                if (calendarPopup.open) {
                  setCalendarPopup({ open: false, date: null, setDate: () => {} })
                } else setCalendarPopup({ open: true, date: value, setDate: setValue })
              }}
              onBlur={() => setCalendarPopup({ open: false, date: null, setDate: () => {} })}
            >
              <InputView fullWidth={fullWidth}>
                <StyledButton onClick={() => setCalendarPopup({ open: true, date: value, setDate: setValue })}>
                  <img style={{ width: "14px", height: "14px" }} src={calendarIcon} alt={"달력 아이콘"} />
                  <StyledP>{value === null ? "" : moment(value).format("YYYY.MM.DD")}</StyledP>
                </StyledButton>
              </InputView>
            </Box>
          </Box>
        )
      case "select":
        return (
          <Box>
            <InputView fullWidth={fullWidth}>
              <StyledSelect onClick={() => setOpen(!open)} onBlur={() => setOpen(false)}>
                <StyledP select={value}>{value}</StyledP>
                <img style={{ width: "8px", height: "6px", marginRight: "10px" }} src={dropIcon} alt={"드롭 아이콘"} />
              </StyledSelect>
              <OptionBox open={open} onMouseDown={(e) => e.preventDefault()}>
                {item.map((option, optionIndex) => (
                  <Option
                    key={optionIndex}
                    onClick={(e) => {
                      setValue(option)
                      setOpen(false)
                    }}
                  >
                    {option.name}
                  </Option>
                ))}
              </OptionBox>
            </InputView>
          </Box>
        )
      case "price":
        return (
          <Box>
            <InputView fullWidth={fullWidth}>
              <InputStyled
                style={{ textAlign: "right" }}
                value={value}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.replace(/[0-9]/g, "").replace(/,/g, "").length > 0) return
                  else {
                    setValue(value.replace(/,/g, ""))
                  }
                }}
              ></InputStyled>
              <EndString>{"원"}</EndString>
            </InputView>
          </Box>
        )
      case "qty":
        return (
          <Box>
            <InputView fullWidth={fullWidth}>
              <InputStyled
                value={value}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.replace(/[0-9]/g, "").replace(/,/g, "").length > 0) return
                  else {
                    setValue(value.replace(/,/g, ""))
                  }
                }}
              ></InputStyled>
            </InputView>
          </Box>
        )
      case "address":
        return (
          <Box>
            <InputView fullWidth={fullWidth}>
              <StyledP>{value}</StyledP>
              <AddressSearchBtn onClick={onClick}>
                <img style={{}} src={searchIcon} alt={"검색 아이콘"} />
              </AddressSearchBtn>
            </InputView>
          </Box>
        )
      default:
        return (
          <Box>
            <InputView readOnly={readOnly} fullWidth={fullWidth}>
              <InputStyled
                readOnly={readOnly}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onFocus={onFocusIn || (() => {})}
                onBlur={onFocusOut || (() => {})}
                type={isPassword ? "password" : "text"}
              ></InputStyled>
            </InputView>
          </Box>
        )
    }
  }

  return (
    <Container fullWidth={fullWidth} marginLeft={marginLeft}>
      {label !== undefined && (
        <Box>
          <Text>{label}</Text>
        </Box>
      )}
      {render()}
    </Container>
  )
}
export default InputBox

const Container = styled.div`
  flex: ${(props) => (props.fullWidth ? 1 : 0)};
  margin-left: ${(props) => props.marginLeft}px;
`
const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Text = styled.p`
  margin-bottom: 5px;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #445e78;
`
const InputView = styled.div`
  background-color: ${(props) => (props.readOnly ? "#eef2f7" : "#fff")};
  position: relative;
  display: flex;
  flex: ${(props) => (props.fullWidth ? 1 : 0)};
  justify-content: space-between;
  align-items: center;
  min-width: 190px;
  height: 40px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`
const InputStyled = styled.input`
  background-color: ${(props) => (props.readOnly ? "#eef2f7" : "#fff")};
  padding-left: 10px;
  flex: 1;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const EndString = styled.p`
  margin-right: 10px;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const StyledP = styled.p`
  overflow-x: auto;
  flex: 1;
  width: 0px;
  margin-left: 10px;
  margin-right: 10px;
  color: ${(props) => (props.select === "선택" ? "#acb0bb" : "#35363a")};
  font-size: 14px;
  font-family: NanumSquareRegular;
  line-height: 16px;
  letter-spacing: -0.42px;
  text-align: left;
  white-space: nowrap;
`
// DATE
const StyledButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  border-radius: 4px;
`
// SELECT
const StyledSelect = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const OptionBox = styled.div`
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  top: 40px;
  left: -1px;
  display: ${(props) => (props.open ? "block" : "none")};
  width: 100%;
  min-width: 190px;
  max-height: 160px;
  height: auto;
  border: 1px solid #dbdee5;
  border-radius: 4px;
  z-index: 100;
`
const Option = styled.button`
  overflow-x: hidden;
  display: block;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  border-top: 1px solid #dbdee5;
  box-sizing: border-box;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-child {
    border-top: none;
  }
`
// ADDRESS
const AddressSearchBtn = styled.button`
  background-color: #00adee;
  width: 40px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`
